import {
  ADDITIONAL_FILTERS,
  CASE_MANAGER_DROPDOWN,
  COMANAGEMENT_TEAM,
  createProviderDropdown,
  EPISODE_TYPE_DROPDOWN,
  getComanagementTeamAcuteFilters,
  PLAN_TYPE_DROPDOWN,
  STATUS_OUT_OF_DATE_CHECKBOX,
} from 'constants/filterConfigs';
import { PatientState } from 'constants/filterKeysConstants';
import { ClientType } from 'models/Client';
import GroupType from 'models/GroupType';

import { PortfolioTab } from './Portfolio';

const getAdditionalFilters = (hasCaseManagerFlag: boolean, selectedTab: PortfolioTab) => {
  return [
    PLAN_TYPE_DROPDOWN,
    EPISODE_TYPE_DROPDOWN,
    ...(hasCaseManagerFlag ? [CASE_MANAGER_DROPDOWN] : []),
    ...(selectedTab.patientState !== PatientState.CONTINUED ? [STATUS_OUT_OF_DATE_CHECKBOX] : []),
  ];
};

export const renderableFilters = (
  selectedTab: PortfolioTab,
  actingClientType: ClientType,
  hasCaseManagerFlag: boolean
) => {
  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [
        ![PatientState.CONTINUED, PatientState.LATEST].includes(selectedTab.patientState as PatientState) &&
          createProviderDropdown(
            {
              id: selectedTab?.groupType ?? '',
              displayName: selectedTab?.displayName ?? '',
              apiName: selectedTab?.value ?? '',
            } as GroupType,
            {
              include: 'groupType',
            }
          ),
        ...getComanagementTeamAcuteFilters(actingClientType),
      ],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: getAdditionalFilters(hasCaseManagerFlag, selectedTab),
    },
  ];
};
