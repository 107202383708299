import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import Histogram from 'components/shared/charts/Histogram';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../../constants';
import { useInsightsContext } from '../../InsightsContainer';
import Card from '../Card';
import { DataRow, parseDimensionValues, parseMetricValues } from '../helpers/dataUtils';
import useDataFetch from '../helpers/useDataFetch';

import { AlosChartData, generateExtendedChartConfig } from './helpers/alosUtils';
import { GroupRow } from './helpers/utils';
import ChartContainer from './ChartContainer';
import MetricDetailContainer from './MetricDetailContainer';
import MetricDetailHeader from './MetricDetailHeader';
import TableContainer from './TableContainer';

type AlosTableRow = GroupRow & {
  alos: number;
};

const AverageLengthOfStayDetail = () => {
  const navigate = useNavigate();

  const { dischargedId } = useInsightsContext();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const [chartData, setChartData] = useState<AlosChartData>({
    categories: [],
    values: [],
    counts: [],
    average: 0,
  });
  const [totalLocationEpisodes, setTotalLocationEpisodes] = useState(-1);

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODES,
        dimensions: [DimensionConstants.GROUP_NAME],
        metrics: [MetricConstants.AVERAGE_LENGTH_OF_STAY, MetricConstants.ID_COUNT],
        sortBy: `${MetricConstants.ID_COUNT} ${SortOrder.DESC}, ${DimensionConstants.GROUP_NAME} ${SortOrder.DESC}`,
        rehabState: dischargedId,
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const alos = metricValues[0];
            const locationEpisodes = metricValues[1];

            if (!groupName) {
              acc.average = alos;
              acc.totalLocationEpisodes = locationEpisodes;
            } else {
              acc.categories.push(groupName);
              acc.values.push(alos);
              acc.counts.push(locationEpisodes);
            }

            return acc;
          },
          { categories: [], values: [], counts: [], average: 0, totalLocationEpisodes: 0 } as {
            categories: string[];
            values: number[];
            counts: number[];
            average: number;
            totalLocationEpisodes: number;
          }
        );

        const { totalLocationEpisodes, ...chartData } = parsedData;

        setChartData(chartData);
        setTotalLocationEpisodes(totalLocationEpisodes);
      },
    }),
    [dischargedId]
  );

  const { loading } = useDataFetch([request], {
    condition: dischargedId !== undefined,
  });

  const chartConfig = useMemo(() => generateExtendedChartConfig(chartData), [chartData]);

  const getValueString = () => {
    if (chartData.average <= 0) return;

    return `${chartData.average.toFixed(1)} days`;
  };

  const tableData = useMemo<AlosTableRow[]>(() => {
    const { categories, values, counts } = chartData;

    return categories.map((groupName, i) => ({
      groupName,
      alos: values[i],
      locationEpisodes: counts[i],
    }));
  }, [chartData]);

  const columnHelper = createColumnHelper<AlosTableRow>();
  const columns = useMemo<ColumnDef<AlosTableRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: selectedGroupType?.displayName,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('locationEpisodes', {
        header: 'Patients',
        footer: () => `${totalLocationEpisodes} (Total)`,
      }),
      columnHelper.accessor('alos', {
        header: 'Average Length of Stay',
        cell: (data) => `${data.getValue().toFixed(1)} days`,
        footer: () => `${chartData.average.toFixed(1)} days (Avg)`,
      }),
    ],
    [chartData.average, columnHelper, selectedGroupType, totalLocationEpisodes]
  );

  const desktopOnlyColumns = ['locationEpisodes'];

  const defaultSort = [
    {
      id: 'locationEpisodes',
      desc: true,
    },
  ];

  return (
    <>
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.values.length}
        header={<MetricDetailHeader label='Average Length of Stay' loading={loading} value={getValueString()} />}>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <Card.Title>Average Length of Stay Overview</Card.Title>
        <DataTable
          columns={columns}
          data={tableData}
          defaultSortBy={defaultSort}
          desktopOnlyColumns={desktopOnlyColumns}
          loading={loading}
        />
      </TableContainer>
    </>
  );
};

export default AverageLengthOfStayDetail;
