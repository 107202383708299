import { DividedBlurb } from 'components/Containers';
import AltcsDecision from 'components/patient/blurbs/patientOverview/AltcsDecision';
import AltcsPending from 'components/patient/blurbs/patientOverview/AltcsPending';
import CoManagementTeam from 'components/patient/blurbs/patientOverview/CoManagementTeam';
import PatientClassifications from 'components/patient/blurbs/patientOverview/PatientClassifications';
import PatientRehabState from 'components/patient/blurbs/patientOverview/PatientRehabState';
import { SNF } from 'constants/locationTypes';
import Episode from 'models/Episode';
import { Flags } from 'models/Flag';
import LocationEpisode from 'models/LocationEpisode';
import Profile from 'models/Profile';
import { PERMISSIONS } from 'models/Profile';

type Props = {
  locationEpisode: LocationEpisode;
  episode: Episode;
  profile: Profile;
};

export default function PatientOverview({ episode, locationEpisode, profile }: Props) {
  const altcsApplication = locationEpisode?.altcsApplicationReview;
  const showAltcs =
    profile?.has(PERMISSIONS.reviewAltcsApplicationEdit) &&
    locationEpisode?.owner.client.hasFlag(Flags.AltcsPending) &&
    locationEpisode?.rehabInformation.latestRehabFacilityType === SNF &&
    (locationEpisode.currentRehabState.inTreatment || locationEpisode.currentRehabState.admission);

  const patientName = episode.patient.name;

  return (
    <DividedBlurb>
      <PatientRehabState
        locationEpisode={locationEpisode}
        episode={episode}
        patientName={patientName}
        profile={profile}
      />
      <CoManagementTeam locationEpisode={locationEpisode} episode={episode} />
      <PatientClassifications locationEpisode={locationEpisode} />
      {showAltcs && !altcsApplication && <AltcsPending locationEpisode={locationEpisode} patientName={patientName} />}
      {showAltcs && !!altcsApplication && (
        <AltcsDecision
          altcsApplication={altcsApplication}
          locationEpisode={locationEpisode}
          patientName={patientName}
        />
      )}
    </DividedBlurb>
  );
}
