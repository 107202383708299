import { UserPreferenceType } from 'models/UserPreference';
import { PortfolioFilterValue } from 'models/userPreferences/PortfolioFilter';
import http from 'services/http';

type UpsertParams = {
  value: PortfolioFilterValue;
  clientId: string;
};

export async function upsertPortfolioFilter(params: UpsertParams) {
  const sorts = params.value.sorts ?? {};
  delete params.value.sorts;

  return http.put<void>(`me/preferences/portfolio_filter${params.clientId ? `/${params.clientId}` : ''}`, {
    type: UserPreferenceType.PORTFOLIO_FILTER,
    value: {
      ...serializeFilters(params),
      sorts,
    },
  });
}

const serializeFilters = (params: UpsertParams) => {
  return Object.entries(params.value).reduce((acc, [key, value]) => {
    acc[key] = value.serialize();

    return acc;
  }, {});
};
