import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import Histogram from 'components/shared/charts/Histogram';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../../constants';
import Card from '../Card';
import { DataRow, parseDimensionValues, parseMetricValues } from '../helpers/dataUtils';
import useDataFetch from '../helpers/useDataFetch';

import { generateExtendedChartConfig } from './helpers/totalDischargesUtils';
import { ChartData, GroupRow } from './helpers/utils';
import ChartContainer from './ChartContainer';
import MetricDetailContainer from './MetricDetailContainer';
import MetricDetailHeader from './MetricDetailHeader';
import TableContainer from './TableContainer';

const TotalDischargesDetail = () => {
  const navigate = useNavigate();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const [chartData, setChartData] = useState<ChartData>({
    categories: [],
    values: [],
  });
  const [totalDischarges, setTotalDischarges] = useState(-1);

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
        dimensions: [DimensionConstants.GROUP_NAME],
        metrics: [MetricConstants.ID_COUNT],
        sortBy: `${MetricConstants.ID_COUNT} ${SortOrder.DESC}, ${DimensionConstants.GROUP_NAME} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const discharges = metricValues[0];

            if (!groupName) {
              acc.totalDischarges = discharges;
            } else {
              acc.categories.push(groupName);
              acc.values.push(discharges);
            }

            return acc;
          },
          { categories: [], values: [], totalDischarges: 0 } as {
            categories: string[];
            values: number[];
            totalDischarges: number;
          }
        );

        const { totalDischarges, ...chartData } = parsedData;

        setChartData(chartData);
        setTotalDischarges(totalDischarges);
      },
    }),
    []
  );

  const { loading } = useDataFetch([request]);

  const chartConfig = useMemo(() => generateExtendedChartConfig(chartData), [chartData]);

  const getValueString = () => {
    if (totalDischarges <= 0) return;

    return totalDischarges.toString();
  };

  const tableData = useMemo<GroupRow[]>(() => {
    const { categories, values } = chartData;

    return categories.map((groupName, i) => ({
      groupName,
      discharges: values[i],
    }));
  }, [chartData]);

  const columnHelper = createColumnHelper<GroupRow>();
  const columns = useMemo<ColumnDef<GroupRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: selectedGroupType?.displayName,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        footer: () => `${totalDischarges} (Total)`,
      }),
    ],
    [columnHelper, selectedGroupType, totalDischarges]
  );

  const defaultSort = [
    {
      id: 'discharges',
      desc: true,
    },
  ];

  return (
    <>
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.values.length}
        header={<MetricDetailHeader label='Total Discharges' loading={loading} value={getValueString()} />}>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <Card.Title>Total Discharges Overview</Card.Title>
        <DataTable columns={columns} data={tableData} defaultSortBy={defaultSort} loading={loading} />
      </TableContainer>
    </>
  );
};

export default TotalDischargesDetail;
