import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';

import { CaseManager } from 'components/patient/blurbs/CaseManager';
import NewActivity from 'components/patient/blurbs/NewActivity';
import PatientOverview from 'components/patient/blurbs/PatientOverview';
import { OlioLineChart, PatientProgressChart } from 'components/patient/charts/PatientProgressChart';
import EpisodeExportButton from 'components/patient/EpisodeExportButton';
import ButtonGroup from 'components/shared/ButtonGroup';
import { useAuth } from 'context/auth';
import { useEnvironment } from 'context/environment';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { simpleDate } from 'lib/date';
import ProgressUpdate from 'models/activities/ProgressUpdate';
import { getChartData } from 'models/Chart';
import { Flags } from 'models/Flag';
import LocationEpisodeSummary from 'models/LocationEpisodeSummary';
import { RehabStateName } from 'models/RehabState';
import { activityQueryKeys, indexActivities } from 'services/api/activity';
import { attachmentsQueryKeys, indexAttachments } from 'services/api/attachments';
import { episodeQueryKeys, showEpisode } from 'services/api/episode';
import { progressChartQueryKeys, showProgressChart } from 'services/api/locationEpisode/progressChart';
import { locationEpisodeQueryKeys, showLocationEpisode } from 'services/api/locationEpisodes';
import { indexRehabStates, rehabStatesQueryKeys } from 'services/api/rehabStates';
import { useMobileNavigationActions } from 'stores/mobileNavigationStore';
import { useTaskModalActions } from 'stores/taskModalStore';
import { colors } from 'styles/theme/colors';
import { BodySmall, H1, LabelBold } from 'styles/typography';
import DischargedIcon from 'svg/DischargedIcon';
import EditIcon from 'svg/EditIcon';
import HamburgerMenuIcon from 'svg/HamburgerMenuIcon';
import WarnIcon from 'svg/WarnIcon';

import { CollapsibleContent } from '../Collapsible';
import { ExpandCollapse, SkeletonBlurb } from '../Containers';
import { ErrorState, ErrorStateKind } from '../ErrorState';
import DischargeModal from '../modals/DischargeModal';
import EscalateModal from '../modals/EscalateModal';
import Button from '../shared/Button';
import { Select } from '../shared/form/InfiniteScrollDropdown';
import OlioFooter from '../shared/OlioFooter';
import Tabs, { TabType } from '../Tabs';
import { UserBar } from '../UserBar';

import { ActivityList, SkeletonActivityList } from './blurbs/ActivityList';
import ArchivedWarningBanner from './blurbs/ArchivedWarningBanner';
import { Authorization } from './blurbs/Authorization';
import PatientOverviewSkeleton from './blurbs/patientOverview/PatientOverviewSkeleton';
import PatientStatus from './blurbs/PatientStatus';
import { ProjectedDischarge } from './blurbs/ProjectedDischarge';
import ServiceRefusalView from './blurbs/ServiceRefusal';
import { ProgressBarComponent } from './charts/PatientProgressBar';
import { Files } from './filestab/Filestab';
import { useActingClientAuthorizedCheck } from './useActingClientAuthorizedCheck';

function StaySelectorOptionLabel(locationEpisode: LocationEpisodeSummary) {
  const {
    rehabInformation: { latestRehabFacility, latestRehabFacilityType },
  } = locationEpisode;

  if (!locationEpisode.id) return null;
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{locationEpisode.stayDescriptor} </span>- {latestRehabFacility.name} (
      {latestRehabFacilityType})
    </>
  );
}

function PatientPage() {
  const { errorCode: emrErrorCode, decodedToken } = useAuth();
  const { environment } = useEnvironment();
  const navigate = useNavigate();
  let { episodeId } = useParams<{ episodeId?: string }>();
  let emrView = false;
  if (!episodeId && decodedToken) {
    episodeId = decodedToken.episode;
    emrView = true;
  }
  const [selectedLocationEpisodeId, setSelectedLocationEpisodeId] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [showDischarge, setShowDischarge] = useState(false);
  const [showEscalate, setShowEscalate] = useState(false);
  const { profile } = useProfile();
  const { setCurrentPatientName } = useTaskModalActions();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (environment?.OLIO_API_DOMAIN && !episodeId) {
      navigate('/patients');
    }
  }, [environment, episodeId, navigate]);

  const { data: rehabStates } = useQuery({
    queryKey: rehabStatesQueryKeys.index,
    queryFn: indexRehabStates,
    enabled: showDischarge,
  });

  const episodeQueryParams = {
    id: episodeId ?? '',
    include: 'episode_groups.location.group_type,owner.client.enabled_flags',
  };
  const authorizationChecked = useActingClientAuthorizedCheck(episodeId);

  const { data: episode, isLoading: loadingEpisode } = useQuery({
    queryKey: episodeQueryKeys.show(episodeQueryParams),
    queryFn: () => showEpisode(episodeQueryParams),
    enabled: authorizationChecked,
  });

  useEffect(() => {
    setCurrentPatientName(episode?.patient?.name || '');

    return () => setCurrentPatientName('');
  }, [episode, setCurrentPatientName]);

  const locationEpisodeQueryParams = {
    id: selectedLocationEpisodeId,
    include:
      'reviews.attrs.attr_values,last_valid_predecessor,question_templates,owner.client.group_types,owner.client.enabled_flags,case_manager.credential,latest_progress_update',
  };
  const { data: locationEpisode, isLoading: _loadingLocationEpisode } = useQuery({
    queryKey: locationEpisodeQueryKeys.show(locationEpisodeQueryParams),
    queryFn: () => showLocationEpisode(locationEpisodeQueryParams),
    enabled: !!selectedLocationEpisodeId,
  });

  const activitiesQueryParams = {
    locationEpisodeId: selectedLocationEpisodeId,
    include: 'attachments.blob',
  };
  const activitiesQuery = useInfiniteQuery({
    queryKey: activityQueryKeys.index(activitiesQueryParams),
    queryFn: ({ pageParam, signal }) => indexActivities({ ...activitiesQueryParams, page: pageParam }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    enabled: !!selectedLocationEpisodeId,
  });

  useEffect(() => {
    setSelectedLocationEpisodeId(episode?.locationEpisodes?.find((le) => le.latest)?.id ?? '');
  }, [episode?.locationEpisodes]);

  const patientStoryTab: TabType = useMemo(
    () => ({
      label: 'Patient Story',
      value: 'patient-story',
    }),
    []
  );

  const { data: attachmentsCount } = useQuery({
    queryKey: attachmentsQueryKeys.count({ locationEpisodeId: selectedLocationEpisodeId }),
    queryFn: ({ signal }) =>
      indexAttachments({ locationEpisodeId: selectedLocationEpisodeId, pageSize: 1, page: 1 }, signal),
    select: (data) => data?.meta.totalRecords,
    enabled: !!selectedLocationEpisodeId,
  });

  const filesTab = useMemo(
    () =>
      ({
        label: `Files (${attachmentsCount ?? '-'})`,
        value: 'files',
        'data-gtm-id': 'patientFiles',
      }) as TabType,
    [attachmentsCount]
  );
  const tabs = useMemo(() => [patientStoryTab, filesTab], [patientStoryTab, filesTab]);
  const [selectedTab, setSelectedTab] = useState(patientStoryTab);

  const { data: progressChartData } = useQuery({
    queryKey: progressChartQueryKeys.show(selectedLocationEpisodeId),
    queryFn: () => showProgressChart(selectedLocationEpisodeId),
    enabled: !!selectedLocationEpisodeId,
  });
  const invalidateData = () => {
    queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show(locationEpisodeQueryParams) });
    queryClient.invalidateQueries({
      queryKey: attachmentsQueryKeys.count({ locationEpisodeId: selectedLocationEpisodeId }),
    });
  };

  const chartData = useMemo(
    () =>
      getChartData(
        activitiesQuery.data?.pages[0]?.data?.filter((activity) => activity instanceof ProgressUpdate) ?? [],
        locationEpisode?.progressTemplate?.questions
      ),
    [activitiesQuery?.data, locationEpisode]
  );

  const error: any = emrErrorCode;
  const isMobile = useIsMobile();

  const { setNavigationOpen } = useMobileNavigationActions();
  if (error) {
    const code = typeof error === 'number' ? error : error.response?.status ?? error.status ?? error.code;

    return (
      <ErrorState
        kind={
          code == 404
            ? ErrorStateKind.PatientNotFound
            : code == 403
              ? ErrorStateKind.RequestAccess
              : ErrorStateKind.SomethingWentWrong
        }
      />
    );
  }

  const name = episode?.patient.name;
  const dob = episode?.patient.dateOfBirth;
  const episodeDate = episode?.admittedOn;
  const gender = episode?.patient.sex ?? 'unknown';
  const formattedGender = gender.charAt(0).toUpperCase() + gender.slice(1);
  const externalId = episode?.patient.externalId;

  console.log(locationEpisode);
  const userActions = locationEpisode?.latest && !locationEpisode.archived && !episode?.archived;
  const userCanDischargePatient =
    userActions &&
    profile.permissions.rehabStateDischargeCreate &&
    (profile.isAcute || profile.allowedGroupIds.includes(locationEpisode.locationId)) &&
    !locationEpisode.currentRehabState.queue;

  const currentRehabState = episode?.currentRehabState.state;
  const canDischargeState =
    currentRehabState !== RehabStateName.Discharged && currentRehabState !== RehabStateName.Queue && currentRehabState;
  const userCanEscalate =
    userActions &&
    !profile.isAcute &&
    profile.permissions.escalationCreate &&
    profile.allowedGroupIds.includes(locationEpisode.locationId);

  const showCaseManger =
    locationEpisode?.owner.client.hasFlag(Flags.CaseManagerAssignment) &&
    (profile.isAcute || !!locationEpisode?.caseManager);

  const showEditPatientButton = !episode?.archived && profile.permissions.patientEdit;
  const showActivityInput =
    !!locationEpisode &&
    locationEpisode.latest &&
    !locationEpisode.archived &&
    !episode?.archived &&
    profile.permissions.activityNoteCreate;

  return (
    <Patient>
      {emrView && <UserBar />}
      <Header id='patient-header'>
        {episode?.archived && <ArchivedWarningBanner episode={episode} />}
        <TopContent id='top-content'>
          <ContentRow id='content-row'>
            <InnerContentRow id='inner-content-row'>
              {loadingEpisode && (
                <>
                  <SkeletonBlank $width={200} />
                  <SkeletonBlank $width={80} />
                  <SkeletonBlank $width={100} />
                </>
              )}
              {isMobile && (
                <HamburgerMenuIcon
                  width={16}
                  height={16}
                  color={colors.black}
                  onClick={() => setNavigationOpen(true)}
                />
              )}
              <StyledPatientHeader id='styled-patient-header'>{name}</StyledPatientHeader>
              {!isMobile && profile.isAcute && externalId && <Dob>ID: {externalId}</Dob>}
              {!isMobile && dob && <Dob>DOB: {simpleDate(dob)}</Dob>}
              <InnerContentRow>
                {showEditPatientButton && (
                  <ActionIcon onClick={() => navigate(`/patients/${episode?.id}/edit`)}>
                    <EditIcon color={colors.black} />
                  </ActionIcon>
                )}
                {!episode?.archived && <EpisodeExportButton episodeId={episode?.id ?? ''} />}
              </InnerContentRow>
              <ExpandContainer>
                <ExpandCollapse onClick={() => setExpanded(!expanded)}>
                  {expanded ? 'Collapse' : 'Expand'}
                </ExpandCollapse>
              </ExpandContainer>
            </InnerContentRow>
            <ContentActionsRow id='contents-action-row'>
              <InnerContentRow>
                {(userCanDischargePatient || userCanEscalate) && (
                  <>
                    <ButtonGroup fullWidth>
                      {userCanDischargePatient && (
                        <Button
                          fullWidth
                          variant='outline-primary'
                          onClick={() => setShowDischarge(true)}
                          disabled={!canDischargeState}>
                          {!isMobile && <DischargedIcon width={18} height={18} color={colors.primaryBlue} />}
                          Discharge
                        </Button>
                      )}
                      {userCanEscalate && (
                        <Button fullWidth variant='outline-danger' onClick={() => setShowEscalate(true)}>
                          {!isMobile && <WarnIcon width={18} height={18} color={colors.accentRed} />}
                          Escalate
                        </Button>
                      )}
                    </ButtonGroup>
                    {showDischarge && (
                      <DischargeModal
                        episode={episode!}
                        rehabStates={rehabStates?.data ?? []}
                        locationEpisode={locationEpisode}
                        setShow={setShowDischarge}
                        patientName={name!}
                        invalidateData={invalidateData}
                      />
                    )}
                    {showEscalate && (
                      <EscalateModal locationEpisode={locationEpisode} setShow={setShowEscalate} patientName={name!} />
                    )}
                  </>
                )}
              </InnerContentRow>
            </ContentActionsRow>
          </ContentRow>
          {locationEpisode && isMobile && (
            <CollapsibleContent collapsed={!expanded}>
              <DataRow>
                {profile.isAcute && externalId && (
                  <LabelBold>
                    <span className='info-key'>ID:</span> <span>{externalId}</span>
                  </LabelBold>
                )}
                {dob && (
                  <LabelBold>
                    <span className='info-key'>DOB:</span>
                    <span>{simpleDate(dob)}</span>
                  </LabelBold>
                )}
                <LabelBold>
                  <span className='info-key'>Gender:</span>
                  <span>{formattedGender}</span>
                </LabelBold>
              </DataRow>
              <DataRow>
                <LabelBold>
                  <span className='info-key'>Hospital Admission:</span>
                  <span>{simpleDate(episodeDate) || '—'}</span>
                </LabelBold>
              </DataRow>
              <ProgressBarComponent locationEpisode={locationEpisode} />
            </CollapsibleContent>
          )}
          {locationEpisode && !isMobile && (
            <DesktopCollapsibleContent collapsed={!expanded}>
              <DataRow>
                <LabelBold>
                  <span className='info-key'>Hospital Admission:</span>
                  <span>{simpleDate(episodeDate) || '—'}</span>
                </LabelBold>

                <LabelBold>
                  <span className='info-key'>Gender:</span>
                  <span>{formattedGender}</span>
                </LabelBold>
              </DataRow>
              <ProgressBarComponent locationEpisode={locationEpisode} />
            </DesktopCollapsibleContent>
          )}
        </TopContent>
        <BottomContent>
          <Tabs tabs={tabs} onTabClick={setSelectedTab} selectedTab={selectedTab} />
        </BottomContent>
      </Header>
      <BodyTable>
        <ResponsiveContainer>
          {selectedTab.value === patientStoryTab.value ? (
            <ResponsiveGrid isNavBarOpen={!!episodeId}>
              <LeftColumn>
                {episode ? (
                  episode.locationEpisodes?.length > 1 && (
                    <Select<LocationEpisodeSummary>
                      options={episode?.locationEpisodes ?? []}
                      onChange={(change) => setSelectedLocationEpisodeId(change!.id)}
                      getOptionValue={({ id }) => id}
                      formatOptionLabel={StaySelectorOptionLabel}
                      value={locationEpisode! as LocationEpisodeSummary}
                    />
                  )
                ) : (
                  <SkeletonLocationEpisodeDropdown>
                    <SkeletonLocationEpisodeDropdownInner />
                  </SkeletonLocationEpisodeDropdown>
                )}
                <Authorization
                  locationEpisode={locationEpisode}
                  episode={episode}
                  patientName={name}
                  profile={profile}
                />
                {episode && locationEpisode && profile && (
                  <ProjectedDischarge
                    locationEpisode={locationEpisode}
                    profile={profile}
                    episode={episode}
                    patientName={name!}
                  />
                )}
                {profile && locationEpisode && showCaseManger && (
                  <CaseManager profile={profile} locationEpisode={locationEpisode} patientName={name!} />
                )}

                {profile && locationEpisode && episode ? (
                  <PatientOverview episode={episode} locationEpisode={locationEpisode} profile={profile} />
                ) : (
                  <PatientOverviewSkeleton />
                )}

                {progressChartData && <PatientProgressChart chartData={progressChartData} />}
                {chartData.map((chart, index) => chart && <OlioLineChart key={index} chart={chart} />)}
              </LeftColumn>

              <RightColumn>
                {locationEpisode && (
                  <ServiceRefusalView
                    patientName={name!}
                    locationEpisode={locationEpisode}
                    episode={episode!}
                    profile={profile}
                  />
                )}
                {!episode?.archived && (
                  <PatientStatus locationEpisode={locationEpisode} patientName={name} profile={profile} />
                )}

                {showActivityInput && <NewActivity locationEpisode={locationEpisode} />}

                {profile && episode && locationEpisode && !activitiesQuery.isLoading ? (
                  <ActivityList
                    activitiesQuery={activitiesQuery}
                    episode={episode!}
                    profile={profile}
                    locationEpisode={locationEpisode!}
                  />
                ) : (
                  <SkeletonActivityList />
                )}
              </RightColumn>
            </ResponsiveGrid>
          ) : (
            <Files locationEpisodeId={selectedLocationEpisodeId} initialCount={attachmentsCount ?? 0} />
          )}
        </ResponsiveContainer>
      </BodyTable>
      <OlioFooter />
    </Patient>
  );
}

export default PatientPage;
export const PatientPageAuth0 = withAuthenticationRequired(PatientPage, {});

const MAX_CONTENT_WIDTH = 1072;

const Patient = styled.div`
  height: 100%;
  flex-direction: column;
  overflow-x: hidden;
  @media ${({ theme }) => theme.devices.desktop} {
    display: flex;
  }
  @media not ${({ theme }) => theme.devices.desktop} {
    overflow-y: auto;
  }
`;

const Dob = styled(BodySmall)`
  color: var(--black-75);
  padding-top: 4px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
`;

const Header = styled.div`
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black10};
`;

const TopContent = styled.div`
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;

  @media ${({ theme }) => theme.devices.desktop} {
    padding: 24px 24px 24px 56px;
  }
`;

const BottomContent = styled.div`
  padding-left: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.black10};

  @media ${({ theme }) => theme.devices.desktop} {
    padding: 0px 24px 0px 56px;
  }
`;
const ExpandContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  @media not ${({ theme }) => theme.devices.desktop} {
    justify-content: flex-end;
    padding-right: 24px;
    overflow: visible;
  }
`;
const InnerContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 0;
  padding: 4px;
`;
const ContentActionsRow = styled.div`
  @media ${({ theme }) => theme.devices.desktop} {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    align-items: center;
    padding-right: 0px;
  }
  padding-right: 25px;
`;

const ContentRow = styled.div`
  min-width: 0;
  gap: 6px;
  overflow: hidden;
  flex-direction: column;
  display: flex;
  max-width: ${MAX_CONTENT_WIDTH}px;

  @media ${({ theme }) => theme.devices.desktop} {
    flex-direction: row;
  }

  .icon {
    flex-shrink: 0;
  }
`;

const DataRow = styled.div`
  margin-top: 10px;
  display: flex;
  height: 19px;
  gap: 24px;

  .info-key {
    color: var(--black-50);
    margin-right: 4px;
  }
`;

const BodyTable = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
`;

export const ResponsiveContainer = styled.div`
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin-left: 0;

  @media ${({ theme }) => theme.devices.desktop} {
    margin-left: 32px;
  }
`;

export const ResponsiveGrid = styled.div<{
  isNavBarOpen?: boolean;
}>`
  @media (max-width: ${(props) => (props.isNavBarOpen ? '1050px' : '800px')}) {
    flex-direction: column;
  }
  display: flex;
  flex: 1;
  gap: 36px;
`;

const ResponsiveColumn = styled.div`
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LeftColumn = styled(ResponsiveColumn)`
  width: 36%;

  @media (max-width: 1050px) {
    width: 100%;
  }
`;

const RightColumn = styled(ResponsiveColumn)`
  flex: 1;
`;

const StyledPatientHeader = styled(H1)`
  font-weight: var(--font-weight-extra-bold);
  font-size: 20px;
  @media ${({ theme }) => theme.devices.desktop} {
    font-size: 24px;
  }
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const ActionIcon = styled.div`
  display: flex;
  cursor: pointer;
`;

const SkeletonBlank = styled(SkeletonBlurb)<{ $width: number }>`
  border-radius: 17px;
  height: 17px;
  width: ${({ $width }) => $width + 'px'};
`;

const SkeletonLocationEpisodeDropdown = styled.div`
  height: 34px;
  background-color: white;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  border: 1px solid var(--black-25);
  display: flex;
  align-items: center;
  padding-left: 8px;
`;
const SkeletonLocationEpisodeDropdownInner = styled(SkeletonBlurb)`
  flex: 0.66;
  border-radius: 13px;
  height: 13px;
`;

const DesktopCollapsibleContent = styled(CollapsibleContent)`
  max-width: ${MAX_CONTENT_WIDTH}px;
`;
