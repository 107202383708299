import { camelCase } from 'lodash';

import { SortOrder } from 'components/insights/constants';
import { PortfolioTab } from 'components/portfolio/Portfolio';
import { PatientState } from 'constants/filterKeysConstants';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';
import { RehabStateApiName } from 'models/RehabState';
import { PortfolioFilterState } from 'stores/portfolioStore';

export function applicableTabFilters(filters: PortfolioFilterState, tab: PortfolioTab): PortfolioFilterState {
  if (!tab) return {};

  return Object.entries(filters).reduce((acc, [filterKey, value]) => {
    if (value instanceof FilterValueArray && !value.length) return acc;

    if (
      !['Location::RehabFacility', 'Group::RehabFacility'].includes(value[0]?.type) &&
      !(value instanceof FilterValueBoolean)
    ) {
      acc[filterKey] = value;
      return acc;
    }

    const filterKeyIsCurrentProviderTab = filterKey === camelCase(tab?.value ?? '');

    switch (tab?.patientState) {
      case PatientState.CURRENT:
      case undefined:
        if (filterKeyIsCurrentProviderTab || value instanceof FilterValueBoolean) {
          acc[filterKey] = value;
        }

        return acc;
      case PatientState.LATEST:
      case PatientState.CONTINUED:
        return acc;
      default:
        return acc;
    }
  }, {});
}

type SortOption = {
  attributeName: string;
  direction: SortOrder;
  rehabStateApiName: RehabStateApiName;
  label: string;
  visible: boolean;
};

export const portfolioLaneSortingOptions = (
  rehabStateApiName: RehabStateApiName,
  showSortByProjectedDischargeDate: boolean
): SortOption[] =>
  [
    {
      attributeName: 'lengthOfStay',
      direction: SortOrder.DESC,
      rehabStateApiName,
      label: 'Length of Stay (Highest)',
      visible: rehabStateApiName !== RehabStateApiName.Queue,
    },
    {
      attributeName: 'lengthOfStay',
      direction: SortOrder.ASC,
      rehabStateApiName,
      label: 'Length of Stay (Lowest)',
      visible: rehabStateApiName !== RehabStateApiName.Queue,
    },
    {
      attributeName: 'patientName',
      direction: SortOrder.ASC,
      rehabStateApiName,
      label: 'Patient Name (A-Z)',
      visible: true,
    },
    {
      attributeName: 'patientName',
      direction: SortOrder.DESC,
      rehabStateApiName,
      label: 'Patient Name (Z-A)',
      visible: true,
    },
    {
      attributeName: 'projectedDischargeDate',
      direction: SortOrder.ASC,
      rehabStateApiName,
      label: 'Upcoming Projected Discharge Date',
      visible: showSortByProjectedDischargeDate,
    },
  ].filter(({ visible }) => visible);
