import styled from 'styled-components';

import Checkbox from 'components/shared/form/Checkbox';
import FieldLabel from 'components/shared/form/FieldLabel';
import { colors } from 'styles/theme/colors';

const PATIENT_NEEDS_OPTIONS = [
  'Oxygen',
  'BiPAP',
  'IV Antibiotics',
  'CPAP',
  'Wound Vac',
  'Specialty Mattress',
  'Trilogy Vent',
  'Bariatric Equipment',
];

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
};

export default function PostAcuteNeedsList({ value, onChange }: Props) {
  const handleNeedsChanged = (need: string, checked: boolean) => {
    const needs = value || [];
    const index = needs.indexOf(need);
    if (checked && index === -1) needs.push(need);
    if (!checked && index != -1) needs.splice(index, 1);

    onChange(needs);
  };

  return (
    <>
      <FieldLabel>Select all that apply</FieldLabel>
      <Container>
        {PATIENT_NEEDS_OPTIONS.map((option) => {
          const checked = !!value.includes(option);

          return (
            <Need key={option} className={checked ? 'active' : ''} onClick={() => handleNeedsChanged(option, !checked)}>
              <Checkbox
                checked={checked}
                checkedColor={colors.primaryBlue}
                color={checked ? colors.primaryBlue : colors.black}
                labelSize='14px'
                size={16}
                label={option}
              />
            </Need>
          );
        })}
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px 10px;
`;

const Need = styled.div`
  display: flex;
  flex: 1 0 41%;
  height: 32px;
  border: 1px solid #969696;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  cursor: pointer;
  padding-left: 15px;

  &.active {
    background: ${({ theme }) => theme.colors.primaryBlue10};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: 'Lato Bold', 'Lato';
    font-weight: bold;
  }
`;
