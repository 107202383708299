import { useRef, useState } from 'react';

import { createPresigned } from 'services/api/uploads';

import Upload from './Upload';

export default function useDirectUpload(initialState: Upload[] = []) {
  const [uploads, setUploads] = useState<Upload[]>(initialState);
  const [uploading, setUploading] = useState(false);
  const sequentialId = useRef(Math.max(...uploads.map((x) => +x.id), 0) + 1);

  const updateFiles = (upload: Upload) => {
    setUploads((uploads) => uploads.map((x) => (x.id != upload.id ? x : new Upload(upload))));
  };

  const uploadFiles = async (files: File[]) => {
    if (uploading) return null;

    const newUploads = files.map(
      (x) =>
        new Upload({
          id: '' + sequentialId.current++,
          file: x,
        })
    );

    setUploads((prevUploads) => [...prevUploads, ...newUploads]);

    setUploading(true);

    const results = await Promise.all(newUploads.map((upload) => upload.start(createPresigned, updateFiles)));

    setUploading(false);
    return results;
  };

  const removeUpload = (id: string) => {
    setUploads((prevUploads) => prevUploads.filter((x) => x.id != id));
  };

  const setDocType = (id: string, docType: string | null) => {
    const upload = uploads.find((x) => x.id == id)!;
    upload.docType = docType ?? undefined;
    updateFiles(upload);
  };

  const resetUploads = () => {
    setUploads([]);
  };

  return {
    removeUpload,
    setDocType,
    uploadFiles,
    resetUploads,
    setUploads,
    uploads,
    uploading,
  };
}
