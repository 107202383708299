import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Page from 'components/layouts/page/Page.tsx';
import ButtonGroup from 'components/shared/ButtonGroup.tsx';
import MobilePageHeader from 'components/shared/MobilePageHeader.tsx';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly.tsx';
import MobileOnly from 'components/shared/responsiveness/MobileOnly.tsx';
import { useDiscardChanges } from 'context/discardChanges.tsx';
import { Form, FormProvider } from 'context/form/index.tsx';
import { FormHelpers, FormMeta, FormValues } from 'context/form/types.ts';
import { useProfile } from 'context/profile.tsx';
import isDeepEqual from 'lib/isDeepEqual.ts';
import { Flags } from 'models/Flag.ts';
import { Notification, NotificationOptions } from 'models/Notifications.ts';
import {
  createNotificationPreferences,
  fetchNotificationPreferences,
  notificationPreferencesQueryKeys,
  updateNotificationPreferences,
} from 'services/api/notificationPreferences.ts';
import { useToastActions } from 'stores/toastStore.ts';
import { H2 } from 'styles/typography.tsx';

import Button from '../shared/Button.tsx';
import Tabs, { TabType } from '../Tabs.tsx';

import NotificationPreferencesContainer from './NotificationPreferencesContainer.tsx';

export type PreferencesFormValues = {
  [groupTypeId: string]: Notification;
};

export type PreferencesFormMeta = {
  isSubmitting: boolean;
};

export default function NotificationPreferences() {
  const queryClient = useQueryClient();
  const { profile } = useProfile();
  const { addToast } = useToastActions();
  const [selectedTab, setSelectedTab] = useState<TabType>();

  // Profile will not be undefined, and will be available on first render, therefore will always have an id.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = useMemo(() => ({ id: profile.id, include: 'groupType,scopes' }), []);

  const { data: notificationPreferences } = useQuery({
    queryKey: notificationPreferencesQueryKeys.index(queryParams),
    queryFn: () => fetchNotificationPreferences(queryParams),
  });

  const { mutate: updatePreferences } = useMutation({
    mutationFn: updateNotificationPreferences,
  });

  const { mutate: createPreferences } = useMutation({
    mutationFn: createNotificationPreferences,
    onSuccess: (data) => {
      queryClient.setQueryData<Notification[]>(notificationPreferencesQueryKeys.index(queryParams), (prefs) => {
        return prefs?.map((pref) => {
          if (pref.groupTypeApiName === data.groupTypeApiName) {
            return { ...pref, ...data };
          } else {
            return pref;
          }
        });
      });
    },
  });

  const initialValues = useMemo(() => {
    return notificationPreferences?.reduce((acc, preference) => {
      acc[preference.groupType.id] = preference;
      return acc;
    }, {}) as PreferencesFormValues;
  }, [notificationPreferences]);

  const handleSubmit = async (
    values: FormValues<PreferencesFormValues>,
    meta: FormMeta<PreferencesFormMeta>,
    helpers: FormHelpers<PreferencesFormValues, PreferencesFormMeta>
  ) => {
    helpers.setMeta('isSubmitting', true);

    for (const key in values) {
      // ignore tabs that have not been changed
      if (isDeepEqual(values[key], initialValues[key])) {
        continue;
      }

      const prefs = {
        ...values[key],
        scopes: {
          locations: values[key]?.scopes.locations?.map((s) => s.id) ?? [],
          planTypes: values[key]?.scopes.planTypes?.map((s) => s.id) ?? [],
          episodes: values[key]?.scopes.episodes?.map((s) => s.id) ?? [],
          caseManagers: values[key]?.scopes.caseManagers?.map((s) => s.id) ?? [],
        },
      } as NotificationOptions;

      if (prefs.id) {
        updatePreferences({ ...prefs, urlExtension: `/${prefs.id}`, include: 'groupType,scopes' });
      } else {
        createPreferences({ ...prefs, clientId: profile.actingClientId ?? '', include: 'groupType,scopes' });
      }
    }

    // updates the cached values so that when the form resets,
    // the values read from the notificationPreferences useQuery are up to date
    queryClient.setQueryData<Notification[]>(
      notificationPreferencesQueryKeys.index(queryParams),
      Object.values(values) as Notification[]
    );

    addToast({ text: 'Your changes have been saved!' });
  };

  const tabs = useMemo(() => {
    const vals =
      notificationPreferences?.map((preference) => ({
        label: preference.groupType.displayName,
        value: preference.groupType.id,
      })) ?? [];

    if (!selectedTab) setSelectedTab(vals[0]);
    return vals;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPreferences]);

  const FormActions = ({ dirty, meta }) => {
    const { openDiscardChangesModal } = useDiscardChanges();

    const handleDiscardChanges = () => {
      // In this case, the modal is being triggered when attempting to discard changes without
      // leaving the page, so the `navigatingAway` config should be set to `false`.
      // This prevents a possible inadvertent redirect to another page that was previously
      // attempted to be navigated to and was blocked by the modal.
      openDiscardChangesModal({ navigatingAway: false });
    };

    return (
      <StyledButtonGroup>
        <Button
          variant='ghost'
          data-cy='clickable'
          disabled={!dirty || meta.isSubmitting}
          onClick={handleDiscardChanges}>
          Discard Changes
        </Button>
        <Button data-cy='clickable' disabled={!dirty} loading={meta.isSubmitting} type='submit'>
          Save Changes
        </Button>
      </StyledButtonGroup>
    );
  };

  return (
    <FormProvider<PreferencesFormValues, PreferencesFormMeta>
      initialValues={initialValues}
      resetOnInitialValuesChange
      withDiscardChanges
      initialMeta={{ isSubmitting: false }}
      onSubmit={handleSubmit}>
      {({ dirty, meta }) => (
        <StyledForm>
          <Page>
            <DesktopOnly>
              <Page.Header>
                <TopSection>
                  <TitleSection>
                    <Page.Title>Notification Preferences</Page.Title>
                    <Subtitle>{profile.actingClient?.name}</Subtitle>
                  </TitleSection>
                  <FormActions {...{ dirty, meta }} />
                </TopSection>
                <TabContainer>
                  <Tabs
                    tabs={tabs}
                    onTabClick={setSelectedTab}
                    selectedTab={tabs.find((tab) => tab.value === selectedTab?.value) || tabs[0]}
                  />
                </TabContainer>
              </Page.Header>
            </DesktopOnly>
            <MobileOnly>
              <StyledMobilePageHeader
                title='Notification Preferences'
                actionElement={
                  <StyledSaveButton variant='ghost' type='submit' data-cy='clickable' disabled={!dirty}>
                    Save
                  </StyledSaveButton>
                }>
                <Tabs
                  tabs={tabs}
                  onTabClick={setSelectedTab}
                  selectedTab={tabs.find((tab) => tab.value === selectedTab?.value) || tabs[0]}
                />
              </StyledMobilePageHeader>
            </MobileOnly>
            <Page.Content>
              {selectedTab && (
                <NotificationPreferencesContainer
                  actingClientId={profile.actingClientId || ''}
                  hasCaseManagerAssignmentFlag={profile.hasFlag(Flags.CaseManagerAssignment)}
                  selectedGroupType={selectedTab}
                />
              )}
            </Page.Content>
          </Page>
        </StyledForm>
      )}
    </FormProvider>
  );
}

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 24px;
`;

const TabContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black10};
  padding: 0 24px;
`;

const TitleSection = styled.div``;

const StyledButtonGroup = styled(ButtonGroup)`
  & > button {
    font-size: 16px;
    font-weight: 700;
  }
`;

const Subtitle = styled(H2)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-size: 16px;
  margin-top: 12px;
`;

const StyledSaveButton = styled(Button)`
  padding: 0;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.primaryBlue25 : theme.colors.primaryBlue)};
  font-size: 14px;
`;

const StyledMobilePageHeader = styled(MobilePageHeader)`
  padding-bottom: 0px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
