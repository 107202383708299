import Review, {
  BaseReviewData,
  BaseReviewDataOptions,
  getDefaults as getReviewDefaults,
  ReviewOptions,
  ReviewType,
} from 'models/Review';

type AuthorizationData = {
  authorizationNumber: string;
  approvedThroughDate: string;
};

export interface AuthorizationOptions extends ReviewOptions {
  type: ReviewType.AUTHORIZATION;
  data: Partial<AuthorizationData & BaseReviewDataOptions>;
}

export function getDefaults(): AuthorizationOptions {
  const defaults = getReviewDefaults();
  return {
    ...defaults,
    type: ReviewType.AUTHORIZATION,
    data: {
      ...defaults.data,
      authorizationNumber: '',
      approvedThroughDate: '',
    },
  };
}

export default class Authorization extends Review {
  declare type: ReviewType.AUTHORIZATION;
  declare data: AuthorizationData & BaseReviewData;

  constructor(options: Partial<AuthorizationOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  static get type() {
    return ReviewType.AUTHORIZATION;
  }
}
