import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { camelCase } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation, useQuery } from '@tanstack/react-query';

import { applicableTabFilters } from 'components/portfolio/helpers';
import usePortfolioTabs from 'components/portfolio/usePortfolioTabs';
import Button from 'components/shared/Button';
import FilterChips from 'components/shared/FilterChips';
import FilterBar from 'components/shared/filters/FilterBar';
import FilterMultiSelect, { FilterMultiSelectProps } from 'components/shared/filters/FilterMultiSelect';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly';
import MobileOnly from 'components/shared/responsiveness/MobileOnly';
import { PatientState } from 'constants/filterKeysConstants';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { capitalize } from 'lib/util';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import { RehabStateName } from 'models/RehabState';
import { indexPortfolio } from 'services/api/portfolio';
import { upsertPortfolioFilter } from 'services/api/preferences';
import { indexRehabStates, rehabStatesQueryKeys } from 'services/api/rehabStates';
import { usePortfolioActions, usePortfolioStore } from 'stores/portfolioStore';
import { FilterOption } from 'stores/types';
import colors from 'styles/theme/colors';
import PlusIcon from 'svg/PlusIcon';

import OlioFooter from '../shared/OlioFooter';
import Tabs, { TabType } from '../Tabs';

import { PortfolioLane } from './PortfolioLane';
import { renderableFilters } from './renderableFilters';

export type PortfolioTab = {
  value: string;
  displayName: string;
  locationType?: string;
  groupType?: string;
  patientState?: PatientState;
  count?: number;
  label?: string;
};

export default function PortfolioPage() {
  const { profile } = useProfile();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [scrolledTab, setScrolledTab] = useState<TabType>();
  const [selectedTab, setSelectedTab] = useState<PortfolioTab>();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsTab = searchParams.get('tab');

  const selectedTabInStore = usePortfolioStore((state) => state.selectedTab);
  const filters = usePortfolioStore((state) => state.filters);
  const search = usePortfolioStore((state) => state.search);
  const sorts = usePortfolioStore((state) => state.sorts);

  const {
    setSelectedTab: setSelectedTabInStore,
    setSearch,
    setFilters,
    setFilter,
    removeFilter,
  } = usePortfolioActions();

  const tabs = usePortfolioTabs(search);
  const lanesWrapperRef = useRef<HTMLDivElement>(null);

  const laneRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  const applicableFilters = useMemo(() => {
    if (!selectedTab) return {};

    return applicableTabFilters(filters, selectedTab);
  }, [filters, selectedTab]);

  const renderableDropdowns = useMemo(() => {
    if (!selectedTab) return [];

    return renderableFilters(selectedTab, profile.actingClient?.clientType, profile.hasFlag('case_manager_assignment'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, profile.actingClientId]);

  const enabledProviderFilterName = camelCase(selectedTab?.value ?? '');

  const currentTabFilter = useMemo(
    () => ({
      locationType: selectedTab?.locationType,
      patientState: selectedTab?.patientState,
    }),
    [selectedTab]
  );

  const basePortfolioParams = useMemo(
    () => ({
      locationType: currentTabFilter.locationType,
      patientState: currentTabFilter.patientState,
      active: true,
    }),
    [currentTabFilter]
  );

  const portfolioParams = useMemo(() => {
    return {
      ...basePortfolioParams,
      filters: applicableFilters,
      currentRehabState: 'All',
      search,
      sortBy: Object.values(sorts)
        .map((lane) => `${lane.key}.${lane.attributeName} ${lane.direction}`)
        .join(','),
    };

    // Do not include `sorts` in dependency array as when the initial sort changes, the PortfolioLane handles the query
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePortfolioParams, applicableFilters, search]);

  const { data: portfolio, isLoading: loadingPortfolio } = useQuery({
    queryKey: ['portfolio', profile.actingClientId, { ...portfolioParams }],
    queryFn: ({ signal }) => {
      return indexPortfolio(portfolioParams, signal) as any;
    },
    enabled: !!selectedTab,
  });

  const slideOutCountQuery = useCallback(
    (params, signal) => {
      return indexPortfolio({ ...params, ...basePortfolioParams }, signal);
    },
    [basePortfolioParams]
  );

  const { data: rehabStates } = useQuery({
    queryKey: rehabStatesQueryKeys.index,
    queryFn: indexRehabStates,
  });

  const mobileTabs = useMemo(() => {
    return [
      { label: 'Queue', value: 'queue' } as TabType,
      { label: 'Admission', value: 'admission' } as TabType,
      { label: 'In Treatment', value: 'in-treatment' } as TabType,
      { label: 'Discharged', value: 'discharged' } as TabType,
    ].map((x) => {
      const laneCount = portfolio?.[x.label.toLowerCase()]?.meta?.totalRecords ?? 0;
      return {
        ...x,
        label: laneCount !== undefined ? `${x.label} (${laneCount})` : x.label,
      };
    });
  }, [portfolio]);

  const { mutate } = useMutation({
    mutationFn: upsertPortfolioFilter,
  });

  const handleChangeProviders = async (changes: FilterValueArray) => {
    setFilter(enabledProviderFilterName, changes);

    const updatedFilters = { ...filters };
    updatedFilters[enabledProviderFilterName] = changes;

    mutate({ clientId: profile.actingClient.id, value: updatedFilters });
  };

  const handleApplyFilters = async ({ search, ...updatedFilters }) => {
    const newFilters = { ...filters, ...updatedFilters };
    setFilters(newFilters);
    setSearch(search);
    mutate({ clientId: profile.actingClient.id as string, value: newFilters });
  };

  const handleClearFilters = async () => {
    setFilters({});
    mutate({ clientId: profile.actingClient.id, value: {} });
  };

  const handleRemoveFilter = async (filterKey: string, id: string) => {
    removeFilter(filterKey, id);

    const updatedFilters = { ...filters };

    updatedFilters[filterKey] = updatedFilters[filterKey].removeFilter(id);

    mutate({ clientId: profile.actingClient.id, value: updatedFilters });
  };

  const handleTabClick = (tab: TabType) => {
    setSearchParams({ tab: tab.value });
  };

  const handleMobileTabClick = (tab: TabType) => {
    const laneRef = laneRefs[mobileTabs.indexOf(tab)].current;
    if (!laneRef || !lanesWrapperRef.current) return;
    lanesWrapperRef.current.scrollTo({ left: laneRef?.offsetLeft, behavior: 'smooth' });
  };

  const changeSelectedTab = useCallback(
    (tab: PortfolioTab) => {
      setSelectedTab(tab);
      setSelectedTabInStore(tab.value);

      if (searchParamsTab !== tab.value) setSearchParams({ tab: tab.value });
    },
    [setSelectedTabInStore, setSearchParams, searchParamsTab]
  );

  useEffect(() => {
    const tabFromSearchParams = tabs.find((x) => x.value === searchParamsTab);
    const tabInStore = tabs.find((x) => x.value === selectedTabInStore);
    const tabToSelect = tabFromSearchParams ?? tabInStore ?? tabs[0];

    if (!tabToSelect || tabToSelect.value === selectedTab?.value) return;

    changeSelectedTab(tabToSelect);
  }, [selectedTab, tabs, selectedTabInStore, changeSelectedTab, searchParamsTab]);

  return (
    <Portfolio>
      <FilterBar
        title={isMobile ? `${capitalize(selectedTab?.displayName ?? '')} Patients` : 'Patient Portfolio'}
        filters={{ ...applicableFilters, search }}
        filterSections={renderableDropdowns}
        queryFn={slideOutCountQuery}
        quickFilterShowMinWidth={1100}
        applyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        quickFilter={
          selectedTab?.locationType && (
            <FilterMultiSelect
              {...(renderableDropdowns[0].filters[0] as FilterMultiSelectProps)}
              onChange={handleChangeProviders}
              value={filters[camelCase(enabledProviderFilterName)] as FilterOption[]}
            />
          )
        }
        callToAction={
          isMobile
            ? null
            : profile.canCreatePatient && (
                <AddPatientButton onClick={() => navigate('/patients/new')}>
                  <PlusIcon color={colors.white} width={14} height={14} />
                  Add Patient
                </AddPatientButton>
              )
        }>
        <MobileOnly>
          <TabFiltersWrapper>
            <Tabs tabs={mobileTabs} onTabClick={handleMobileTabClick} selectedTab={scrolledTab ?? mobileTabs[0]} />
          </TabFiltersWrapper>
        </MobileOnly>
        <DesktopOnly>
          {tabs.length > 1 && (
            <TabFiltersWrapper>
              <Tabs tabs={(tabs ?? []) as TabType[]} onTabClick={handleTabClick} selectedTab={selectedTab as TabType} />
            </TabFiltersWrapper>
          )}
        </DesktopOnly>
      </FilterBar>
      <DndProvider backend={HTML5Backend}>
        <BodyTable>
          <DesktopOnly>
            <FilterChips filters={applicableFilters} onRemoveFilter={handleRemoveFilter} />
          </DesktopOnly>
          <div className='responsive-container'>
            <SwimlaneColumns
              ref={lanesWrapperRef}
              onScroll={(x: any) => {
                const gapsize = 20;
                const scrollunit = (x.target.scrollWidth - gapsize * 5) / 4 + gapsize;
                const index = Math.round(x.target.scrollLeft / scrollunit);
                if (scrolledTab != mobileTabs[index]) setScrolledTab(mobileTabs[index]);
              }}>
              <PortfolioLane
                loadingPortfolio={loadingPortfolio}
                initialColumnData={portfolio?.queue}
                filters={applicableFilters}
                ref={laneRefs[0]}
                patientState={currentTabFilter.patientState}
                locationType={currentTabFilter.locationType}
                debouncedSearch={search}
                currentRehabState={RehabStateName.Queue}
                profile={profile}
                rehabStates={rehabStates?.data ?? []}
              />
              <PortfolioLane
                loadingPortfolio={loadingPortfolio}
                initialColumnData={portfolio?.admission}
                filters={applicableFilters}
                ref={laneRefs[1]}
                patientState={currentTabFilter.patientState}
                locationType={currentTabFilter.locationType}
                debouncedSearch={search}
                currentRehabState={RehabStateName.Admission}
                profile={profile}
                rehabStates={rehabStates?.data ?? []}
              />
              <PortfolioLane
                loadingPortfolio={loadingPortfolio}
                initialColumnData={portfolio?.['in treatment']}
                filters={applicableFilters}
                ref={laneRefs[2]}
                patientState={currentTabFilter.patientState}
                locationType={currentTabFilter.locationType}
                debouncedSearch={search}
                currentRehabState={RehabStateName.InTreatment}
                profile={profile}
                rehabStates={rehabStates?.data ?? []}
              />
              <PortfolioLane
                loadingPortfolio={loadingPortfolio}
                initialColumnData={portfolio?.discharged}
                filters={applicableFilters}
                ref={laneRefs[3]}
                patientState={currentTabFilter.patientState}
                locationType={currentTabFilter.locationType}
                debouncedSearch={search}
                currentRehabState={RehabStateName.Discharged}
                profile={profile}
                rehabStates={rehabStates?.data ?? []}
              />
            </SwimlaneColumns>
          </div>
          <OlioFooter />
        </BodyTable>
      </DndProvider>
    </Portfolio>
  );
}

const TabFiltersWrapper = styled.div`
  background-color: white;
  width: 100%;
  margin-bottom: -24px;
`;

const Portfolio = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const BodyTable = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  padding: 0 24px 24px 24px;
  gap: 24px;
  margin-top: 24px;

  @media ${({ theme }) => theme.devices.desktop} {
    min-height: auto;
    overflow-y: auto;
  }

  .responsive-container {
    flex: 1;
    display: flex;
    justify-content: center;
    max-width: 1184px;
    max-height: 100%;
    min-height: 0;

    @media ${({ theme }) => theme.devices.desktop} {
      min-height: auto;
    }
  }
`;

const SwimlaneColumns = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  gap: 20px;

  @media ${({ theme }) => theme.devices.desktop} {
    gap: 10px;
  }
`;

const AddPatientButton = styled(Button)`
  line-height: 24px;
`;
