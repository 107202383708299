export default function PatientAuthNotAuthorizedIcon({
  width = 42,
  height = 42,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 42 42'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <g id='Rework_Ideation' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='PAC_NotYetAuthorized' transform='translate(-262.000000, -315.000000)'>
          <g id='Group-11' transform='translate(240.000000, 199.000000)'>
            <g id='Group-22' transform='translate(22.000000, 116.000000)'>
              <g id='Group-3' fill='#0F1226' fillOpacity='0.05'>
                <circle id='Oval' cx='21' cy='21' r='21' />
              </g>
              <g id='a-time' transform='translate(14.000000, 13.000000)' stroke='#81828C'>
                <path
                  d='M11.3798067,11.27632 C10.3228667,11.05358 9.01853333,10.8933333 7.45333333,10.8933333 C4.25986667,10.8933333 2.15172,11.5615533 1.01680667,12.06494 C0.395313333,12.3404267 0,12.9561867 0,13.6358733 L0,16.6266667 L9.17333333,16.6266667'
                  id='Path'
                  strokeWidth='2.1'
                />
                <path
                  d='M3.44,4.01333333 C3.44,1.79682667 5.23682667,0 7.45333333,0 C9.66984,0 11.4666667,1.79682667 11.4666667,4.01333333 C11.4666667,6.22984 9.66984,8.6 7.45333333,8.6 C5.23682667,8.6 3.44,6.22984 3.44,4.01333333 Z'
                  id='Path'
                  strokeWidth='2.1'
                  strokeLinecap='square'
                />
                <circle id='Oval' strokeWidth='1.5' strokeLinecap='square' cx='13.76' cy='13.76' r='3.44' />
                <polyline
                  id='Path'
                  strokeWidth='1.2'
                  strokeLinecap='square'
                  points='13.76 12.3266667 13.76 13.76 15.1933333 13.76'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
