import { useEffect, useState } from 'react';
import { GenericAbortSignal } from 'axios';

import { useInfiniteQuery } from '@tanstack/react-query';

import { Paginated } from 'models/Paginated';
import User, { UserOptions } from 'models/User';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';
export const usersQueryKeys = {
  base: 'users' as const,
  index: (params?: Record<string, any>) => [usersQueryKeys.base, params] as const,
};

type IndexParams = {
  active?: boolean;
  group?: string;
  client?: string;
  activeOrCaseManager?: boolean;
} & BaseIndexQueryParams;
export async function indexUsers(params: IndexParams, signal: GenericAbortSignal) {
  return http.get<Paginated<UserOptions>>(`users`, { params, signal }).then((res) => {
    return {
      ...res.data,
      data: res.data.data.map((x) => new User(x)),
    };
  });
}

export const useInfiniteUsers = (params: IndexParams) => {
  const [search, setSearch] = useState<string>();

  const options = useInfiniteQuery({
    queryKey: usersQueryKeys.index({ ...params, search }),
    queryFn: ({ signal, pageParam }) => indexUsers({ pageSize: 10, ...params, search, page: pageParam }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    enabled: false,
  });

  useEffect(() => {
    if (search !== undefined) options.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return { options, onDebouncedSearch: setSearch };
};
