export default function ActivityStateChangeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path
          d='M7.97607182,1.33333333 C5.04515453,1.33667722 2.67001056,3.71182119 2.66666667,6.64273849 C2.66666667,9.38390852 6.94377176,13.8733898 7.43147855,14.3793003 C7.57433941,14.5266677 7.77082467,14.6098475 7.97607182,14.6098475 C8.18131897,14.6098475 8.37780423,14.5266677 8.52066509,14.3793003 C9.00837188,13.8733898 13.285477,9.38390852 13.285477,6.64273849 C13.2821331,3.71182119 10.9069891,1.33667722 7.97607182,1.33333333 Z M7.97607182,8.91819784 C6.71937032,8.91819784 5.70061247,7.89943999 5.70061247,6.64273849 C5.70061247,5.38603699 6.71937032,4.36727914 7.97607182,4.36727914 C9.23277332,4.36727914 10.2515312,5.38603699 10.2515312,6.64273849 C10.2515312,7.24622783 10.011796,7.82499982 9.58506456,8.25173123 C9.15833315,8.67846263 8.57956116,8.91819784 7.97607182,8.91819784 Z'
          id='activity-state-change-path-1'
        />
      </defs>
      <g id='Escalations/Priority-Notes' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Unacknowledged-Escalation' transform='translate(-573.000000, -1012.000000)'>
          <g id='Group-12' transform='translate(559.000000, 995.000000)'>
            <g id='Group-4' transform='translate(14.000000, 17.000000)'>
              <rect
                id='Rectangle-Copy-2'
                fillOpacity='0.25'
                fill='#0F1226'
                opacity='0.25'
                x='0'
                y='0'
                width='24'
                height='24'
                rx='3'
              />
              <g id='Icon/Location' opacity='0.5' transform='translate(4.000000, 4.000000)'>
                <mask id='activity-state-change-mask-2' fill='white'>
                  <use xlinkHref='#activity-state-change-path-1' />
                </mask>
                <g id='Mask' fillRule='nonzero' />
                <g id='Mixin/Fill/Black' mask='url(#activity-state-change-mask-2)' fill='#0F1226' fillRule='evenodd'>
                  <rect id='Rectangle' x='0' y='0' width='16' height='16' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
