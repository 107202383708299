export default function PatientAuthDaysExpiredIcon({
  width = 42,
  height = 42,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 42 42'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <g id='Rework_Ideation' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='PAC_DaysExpired' transform='translate(-262.000000, -315.000000)'>
          <g id='Group-32' transform='translate(262.000000, 315.000000)'>
            <g id='Group-3' fill='#F3F3F4'>
              <circle id='Oval' cx='21' cy='21' r='21' />
            </g>
            <g id='a-delete' transform='translate(13.000000, 12.000000)' stroke='#81828C'>
              <path
                d='M12.2201605,12.1089013 C11.0851697,11.8697154 9.68451648,11.697637 8.00373263,11.697637 C4.57444103,11.697637 2.31061604,12.4151947 1.09189383,12.9557487 C0.424505665,13.2515758 0,13.9128001 0,14.6426711 L0,17.8542881 L9.85074785,17.8542881'
                id='Path'
                strokeWidth='2.1'
              />
              <path
                d='M3.69403044,4.30965575 C3.69403044,1.92949445 5.62354568,0 8.00373263,0 C10.3839196,0 12.3134348,1.92949445 12.3134348,4.30965575 C12.3134348,6.68981706 10.3839196,9.23497661 8.00373263,9.23497661 C5.62354568,9.23497661 3.69403044,6.68981706 3.69403044,4.30965575 Z'
                id='Path'
                strokeWidth='2.1'
                strokeLinecap='square'
              />
              <line
                x1='15.6466816'
                y1='13.9054121'
                x2='13.9055619'
                y2='15.646513'
                id='Path'
                strokeWidth='1.25'
                strokeLinecap='square'
                transform='translate(14.776122, 14.775963) rotate(45.000000) translate(-14.776122, -14.775963) '
              />
              <circle
                id='Oval'
                strokeWidth='2.1'
                strokeLinecap='square'
                transform='translate(14.775906, 14.775963) rotate(-45.000000) translate(-14.775906, -14.775963) '
                cx='14.7759063'
                cy='14.7759626'
                r='3.69397512'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
