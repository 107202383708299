import { FilterMultiSelectProps } from 'components/shared/filters/FilterMultiSelect';
import {
  ADDITIONAL_FILTERS,
  CASE_MANAGER_DROPDOWN,
  COMANAGEMENT_TEAM,
  createProviderDropdown,
  EPISODE_TYPE_DROPDOWN,
  getComanagementTeamAcuteFilters,
  LATEST_REHAB_STATE_DROPDOWN,
  PLAN_TYPE_DROPDOWN,
} from 'constants/filterConfigs';
import GroupType from 'models/GroupType';

export const renderableFilters = (
  actingClientType: string,
  enabledProviderTypes: GroupType[],
  hasCaseManagerFlag: boolean
) => {
  const groupTypeFilters = enabledProviderTypes.map((enabledProviderType: GroupType) =>
    createProviderDropdown(enabledProviderType)
  );

  const additionalFilters: FilterMultiSelectProps[] = [
    PLAN_TYPE_DROPDOWN,
    EPISODE_TYPE_DROPDOWN,
    LATEST_REHAB_STATE_DROPDOWN,
    ...(hasCaseManagerFlag ? [CASE_MANAGER_DROPDOWN] : []),
  ];

  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [...groupTypeFilters, ...getComanagementTeamAcuteFilters(actingClientType)],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: additionalFilters,
    },
  ];
};
