import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import Card from '../../Card';
import { GroupRow } from '../helpers/utils';
import TableContainer from '../TableContainer';

type ReadmissionRateTableRow = GroupRow & {
  readmissions: number;
  readmissionRate: number;
};

type ReadmissionRateDataTableProps = {
  averageRate: number;
  data: {
    categories: string[];
    dischargeCounts: number[];
    readmissionCounts: number[];
    readmissionRates: number[];
  };
  loading: boolean;
  totalDischarges: number;
  totalReadmissions: number;
};

const ReadmissionRateDataTable = (props: ReadmissionRateDataTableProps) => {
  const { averageRate, data, loading, totalDischarges, totalReadmissions } = props;

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const tableData = useMemo<ReadmissionRateTableRow[]>(() => {
    const { categories, dischargeCounts, readmissionCounts, readmissionRates } = data;

    return categories.map((groupName, i) => ({
      groupName,
      discharges: dischargeCounts[i],
      readmissions: readmissionCounts[i],
      readmissionRate: readmissionRates[i],
    }));
  }, [data]);

  const columnHelper = useMemo(() => createColumnHelper<ReadmissionRateTableRow>(), []);
  const columns = useMemo<ColumnDef<ReadmissionRateTableRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: selectedGroupType?.displayName,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('readmissions', {
        header: 'Readmissions',
        footer: () => `${totalReadmissions} (Total)`,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        footer: () => `${totalDischarges} (Total)`,
      }),
      columnHelper.accessor('readmissionRate', {
        header: 'Readmission Rate',
        cell: (cellData) => `${cellData.getValue().toFixed(1)}%`,
        footer: () => `${averageRate.toFixed(1)}% (Avg)`,
      }),
    ],
    [averageRate, columnHelper, selectedGroupType, totalDischarges, totalReadmissions]
  );

  const desktopOnlyColumns = ['readmissions', 'discharges'];

  const defaultSort = [
    {
      id: 'readmissionRate',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <Card.Title>Readmission Rate Overview</Card.Title>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
      />
    </TableContainer>
  );
};

export default ReadmissionRateDataTable;
