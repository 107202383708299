const defaults = {
  locationIds: [],
  groupIds: [],
  name: '',
  title: '',
};

export enum Flags {
  DischargeQuestions = 'discharge_questions',
  ImportImprovements = 'import_improvements',
  AltcsPending = 'altcs_pending',
  CaseManagerAssignment = 'case_manager_assignment',
  Insights = 'insights',
}

export default class Flag {
  locationIds: string[];
  groupIds: string[];
  name: string;
  title: string;

  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.locationIds = opts.locationIds;
    this.groupIds = opts.groupIds;
    this.name = opts.name;
    this.title = opts.title;
  }
}
