import { GenericAbortSignal } from 'axios';

import LocationEpisode from 'models/LocationEpisode';
import { LocationEpisodeOptions } from 'models/LocationEpisode';
import { Paginated } from 'models/Paginated';
import { BaseIndexQueryParams, BaseQueryParams } from 'services/api/types';
import http from 'services/http';

export const locationEpisodeQueryKeys = {
  base: 'locationEpisodes',
  index: (params: Record<string, any> = {}) => ['locationEpisodes', 'index', params],
  show: (params: Record<string, any> = {}) => ['locationEpisodes', 'show', params],
};

type IndexParams = {
  locationType?: string;
  patientState?: string;
  active?: boolean;
} & BaseIndexQueryParams;

export async function indexLocationEpisodes(params: IndexParams, signal: GenericAbortSignal) {
  return http.get<Paginated<LocationEpisodeOptions>>('/location_episodes', { params, signal });
}

type ShowParams = {
  id: string;
} & BaseQueryParams;

export async function showLocationEpisode({ id, ...params }: ShowParams) {
  return http
    .get<LocationEpisodeOptions>(`/location_episodes/${id}`, { params })
    .then((response) => new LocationEpisode(response.data));
}

type UpdateParams = {
  id: string;
  caseManagerId: string | null;
  include?: string;
};
export async function updateLocationEpisode({ id, ...params }: UpdateParams) {
  return http
    .put<LocationEpisodeOptions>(`/location_episodes/${id}`, params)
    .then((response) => new LocationEpisode(response.data));
}
