import { useState } from 'react';
import styled from 'styled-components';

import { Blurb } from 'components/Containers';
import CaseManagerModal from 'components/modals/CaseManagerModal';
import Tooltip from 'components/shared/Tooltip';
import LocationEpisode from 'models/LocationEpisode';
import Profile from 'models/Profile';
import { colors } from 'styles/theme/colors';
import { BodySmallBold, Label, LabelBold } from 'styles/typography';
import InfoIcon from 'svg/InfoIcon';
import PencilIcon from 'svg/PencilIcon';

type CaseManagerProps = {
  patientName: string;
  locationEpisode: LocationEpisode;
  profile: Profile;
};

export function CaseManager(props: CaseManagerProps) {
  const { locationEpisode, profile, patientName } = props;
  const canEditCaseManager = profile.permissions.locationEpisodeUserEdit && profile.isAcute;

  const [showModal, setShowModal] = useState(false);

  return (
    <Blurb data-cy='caseManager'>
      <Header>
        <Label>Case Manager</Label>
        <Tooltip text={`${locationEpisode.owner?.client?.name} case manager`} position='right'>
          <StyledInfoIcon />
        </Tooltip>
        <Spacer />

        {canEditCaseManager && (
          <ActionContainer data-cy='clickable' onClick={() => setShowModal(true)}>
            <PencilIcon width={12} height={12} color={colors.primaryBlue} />
            <StyledLabel>Update</StyledLabel>
          </ActionContainer>
        )}
      </Header>

      <Content>
        <BodySmallBold>{props.locationEpisode.caseManager?.fullName ?? <>&mdash;</>}</BodySmallBold>
      </Content>

      {showModal && (
        <CaseManagerModal
          caseManager={locationEpisode.caseManager}
          setShow={setShowModal}
          patientName={patientName}
          locationEpisode={locationEpisode}
        />
      )}
    </Blurb>
  );
}

const Header = styled.div`
  display: flex;
  padding: 24px 24px 8px;
  align-items: center;
`;

const StyledInfoIcon = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  font-size: 14px;
  margin-left: 8px;
  color: lightgray;
`;

const Content = styled.div`
  padding: 0 24px 24px;
`;
const Spacer = styled.div`
  flex: 1;
`;
const StyledLabel = styled(LabelBold)`
  margin-left: 8px;
  cursor: pointer;
  color: var(--primary-blue);
`;
const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
