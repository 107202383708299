import { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput from 'components/shared/activityInput/useActivityInput';
import Button from 'components/shared/Button';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { HOME_HEALTH_AGENCY } from 'constants/locationTypes';
import Location from 'models/Location';
import LocationEpisode from 'models/LocationEpisode';
import Review, { ReviewStatus } from 'models/Review';
import { episodeQueryKeys } from 'services/api/episode';
import { useInfiniteLocations } from 'services/api/location';
import { updateReview } from 'services/api/reviews';

export type ReassignServiceRefusalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
  serviceRefusal: Review;
};

export default function ReassignServiceRefusal(props: ReassignServiceRefusalType) {
  const { setShow, patientName, locationEpisode, serviceRefusal } = props;
  const queryClient = useQueryClient();
  const [reassignmentLocation, setReassignmentLocation] = useState<Location | null>(null);
  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput();
  const [isUploading, setIsUploading] = useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: updateReview,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: episodeQueryKeys.show({ id: locationEpisode.episodeId }),
      });
      setShow(false);
    },
  });

  const asyncLocationOptions = useInfiniteLocations({
    sortBy: 'name asc',
    ownerId: locationEpisode.owner.id,
    type: HOME_HEALTH_AGENCY,
    viewOnly: true,
  });

  const cancel = () => {
    setShow(false);
    setReassignmentLocation(null);
    resetNote();
  };

  const submit = () => {
    if (!reassignmentLocation) return;

    const note = getSerializedNoteValues();
    delete note.escalationType;

    mutate({
      id: serviceRefusal.id,
      note,
      reassignedTo: {
        id: reassignmentLocation?.id,
        name: reassignmentLocation?.name,
      },
      status: ReviewStatus.REJECTED, // TODO: some conditional logic in main app setting to canceled if reassigning to same location
      include: 'activities.attachments,episode',
    });
  };

  return (
    <StandardModal title='Reassign Services' onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        <InputGroup title={'Where will the patient be receiving services?'}>
          <Select<Location>
            {...asyncLocationOptions}
            onChange={(change) => setReassignmentLocation(change)}
            getOptionValue={(prop) => prop.id}
            getOptionLabel={(prop) => prop.name}
            value={reassignmentLocation}
            populateDefault
          />
        </InputGroup>
        <InputGroup title={'Notes (optional)'}>
          <ActivityInput
            values={note}
            setValues={setNote}
            locationEpisodeId={locationEpisode.id}
            onUploading={setIsUploading}
          />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={!reassignmentLocation || isUploading} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  margin-bottom: 36px;
`;
