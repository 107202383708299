import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { intakeFormValidation } from 'components/intake/intakeFormValidation';
import ReviewPatientDetails from 'components/intake/ReviewPatientDetails';
import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import useIntakeSteps, { AddEditSteps } from 'components/intake/useIntakeSteps';
import FormPage from 'components/layouts/page/FormPage';
import { FormContainer } from 'components/shared/form/Form';
import { Form, FormProvider } from 'context/form';
import { FormHelpers } from 'context/form/types';
import { useProfile } from 'context/profile';
import Episode from 'models/Episode';
import { Flags } from 'models/Flag';
import Patient from 'models/Patient';
import { createPatient } from 'services/api/patient';
import { portfolioQueryKeys } from 'services/api/portfolio';
import { useToastActions } from 'stores/toastStore';

import IntakeForm from './IntakeForm';

export default function AddPatientPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToastActions();
  const { step, nextStep, previousStep } = useIntakeSteps();
  const { profile } = useProfile();
  const episode = new Episode();

  const { mutate: createNewPatient } = useMutation({
    mutationFn: createPatient,
  });

  const handleSubmit = async (values: Partial<FormValues>, _meta, helpers: FormHelpers<FormValues, FormMeta>) => {
    if (step !== AddEditSteps.Confirm) return;

    helpers.setMeta('isSubmitting', true);

    const patientValues = Patient.fromFormValues(values).serialize();

    createNewPatient(patientValues, {
      onSuccess: (patient) => {
        addToast({ text: 'Patient successfully added.' });
        queryClient.invalidateQueries({ queryKey: portfolioQueryKeys.lane('queue') });
        navigate(`/patients/${patient.episodeId}`);
      },
      onError: () => {
        addToast({ text: 'Something went wrong. Please try again.' });
      },
      onSettled: () => {
        helpers.setMeta('isSubmitting', false);
      },
    });
  };

  const initialValues = new Patient().intakeFormValues();

  const steps = {
    [AddEditSteps.Information]: <IntakeForm patient={episode.patient} profile={profile} onNextStep={nextStep} />,
    [AddEditSteps.Confirm]: (
      <ReviewPatientDetails
        clientType={profile.clientType}
        hasCaseManagerAssignmentFlag={profile.hasFlag(Flags.CaseManagerAssignment)}
        onPreviousStep={previousStep}
      />
    ),
  };

  return (
    <FormProvider<FormValues, FormMeta>
      initialValues={initialValues}
      initialMeta={{ isSubmitting: false, mode: PatientFormMode.New }}
      onSubmit={handleSubmit}
      yupSchema={intakeFormValidation(profile.clientType)}>
      <FormPage>
        <Form>
          <FormContainer>{steps[step]}</FormContainer>
        </Form>
      </FormPage>
    </FormProvider>
  );
}
