import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { NoteAttachment } from 'models/Notes';
import { PresignedResponse } from 'services/api/uploads';

enum UploadState {
  pending = 'pending',
  uploading = 'uploading',
  complete = 'complete',
  error = 'error',
}

export interface UploadData {
  file: File;
  docType?: string;
  id: string;
  signedId?: string;
  hasError?: boolean;
  status?: UploadState;
  progress?: number;
  error?: any;
}

export default class Upload {
  file: File;
  docType?: string;
  id: string;
  signedId?: string;
  status: UploadState;
  progress?: number;
  error?: any;

  constructor(props: UploadData) {
    this.file = props.file;
    this.docType = props.docType;
    this.id = props.id;
    this.signedId = props.signedId;
    this.status = props.status ?? UploadState.pending;
    this.progress = props.progress;
    this.error = props.error;
  }

  get filename() {
    return this.file.name;
  }

  get isComplete() {
    return this.status === UploadState.complete;
  }

  get hasError() {
    return this.status === UploadState.error;
  }

  start(presign: (f: File) => Promise<PresignedResponse>, update: (u: Upload) => void): Promise<void> {
    return presign(this.file).then((presigned_url: PresignedResponse) => {
      this.signedId = presigned_url.signedId;
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener(
          'progress',
          ({ loaded, total }) => this.handleProgress(loaded, total, update),
          false
        );

        xhr.addEventListener('load', () => this.handleLoad(xhr, presigned_url, resolve, update), false);
        xhr.addEventListener('error', (error) => this.handleError(error, presigned_url, resolve, update), false);
        xhr.open('PUT', presigned_url.direct_upload.url, true);
        xhr.responseType = 'text';
        for (const [k, v] of Object.entries(presigned_url.direct_upload.headers)) {
          xhr.setRequestHeader(k, v);
        }
        xhr.send(this.file.slice());
      });
    });
  }

  handleLoad(
    xhr: XMLHttpRequest,
    presigned_url: PresignedResponse,
    resolve: (value: void) => void,
    update: (u: Upload) => void
  ) {
    if (xhr.status >= 200 && xhr.status < 300) {
      this.handleSuccess(resolve, update);
    } else {
      this.handleError(xhr.responseText, presigned_url, resolve, update);
    }
  }

  handleProgress(loaded: number, total: number, update: (u: Upload) => void) {
    this.progress = (loaded / total) * 100;
    update(this);
  }

  handleSuccess(resolve: (value: void) => void, update: (u: Upload) => void) {
    this.status = UploadState.complete;
    this.progress = undefined;
    update(this);
    resolve();
  }

  handleError(
    error: any,
    presigned_url: PresignedResponse,
    resolve: (value: void) => void,
    update: (u: Upload) => void
  ) {
    this.status = UploadState.error;
    this.error = error;
    this.progress = undefined;
    update(this);

    const uploadError = new Error(error);
    datadogRum.addError(uploadError);
    datadogLogs.logger.error(
      'Upload error',
      {
        file: JSON.stringify(this.file),
        presigned_url: JSON.stringify(presigned_url),
      },
      uploadError
    );

    resolve();
  }

  serialize(): NoteAttachment {
    return {
      id: this.id,
      signedId: this.signedId!,
      docType: this.docType,
      filename: this.filename,
    };
  }
}
