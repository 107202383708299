import { Note } from 'models/Notes';
import Review, { ReviewOptions, ReviewStatus, ReviewType } from 'models/Review';
import { DischargePayload } from 'services/api/locationEpisodeRehabStates';
import { BaseMutationParams } from 'services/api/types';
import http from 'services/http';

type CreateServiceRefusalInput = {
  locationEpisodeId: string;
  type: ReviewType;
  note?: Note;
  refusedBy: {
    id: string;
    name: string;
    type: string;
  };
} & BaseMutationParams;

type CreateAltcsApplicationInput = {
  type: ReviewType;
  locationEpisodeId: string;
} & BaseMutationParams;

type CreateInput = CreateServiceRefusalInput | CreateAltcsApplicationInput;

export async function createReview({ locationEpisodeId, ...params }: CreateInput) {
  return http
    .post<ReviewOptions>(`/location_episodes/${locationEpisodeId}/reviews`, params)
    .then((response) => new Review(response.data));
}

type UpdateProjectedDischargeReviewInput = {
  id: string;
  projectedDischargeDate: string;
  status: ReviewStatus;
} & BaseMutationParams;

type UpdateAuthorizationReviewInput = {
  id: string;
  approvedThroughDate?: string;
  authorizationNumber?: string;
  note?: Note;
} & BaseMutationParams;

type UpdateServiceRefusalReviewInput = {
  id: string;
  reassignedTo?: {
    id: string;
    name: string;
  };
  note?: Note;
  status: ReviewStatus;
} & BaseMutationParams;

type UpdateAltcsApplicationReviewInput = Partial<DischargePayload> & {
  id: string;
  status: ReviewStatus;
  ltcGroupId?: string;
  providerId?: string;
} & BaseMutationParams;

type UpdateInput =
  | UpdateProjectedDischargeReviewInput
  | UpdateAuthorizationReviewInput
  | UpdateServiceRefusalReviewInput
  | UpdateAltcsApplicationReviewInput;

export async function updateReview({ id, ...params }: UpdateInput) {
  return http.put<ReviewOptions>(`/reviews/${id}`, params).then((response) => new Review(response.data));
}
