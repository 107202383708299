import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { intakeFormValidation } from 'components/intake/intakeFormValidation';
import ReviewPatientDetails from 'components/intake/ReviewPatientDetails';
import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import useIntakeSteps, { AddEditSteps } from 'components/intake/useIntakeSteps';
import FormPage from 'components/layouts/page/FormPage';
import ConfirmPatientRemovalModal from 'components/modals/ConfirmPatientRemovalModal';
import Button from 'components/shared/Button';
import { FormContainer } from 'components/shared/form/Form';
import { ColorThemeName } from 'components/shared/StandardModal';
import { Form, FormProvider } from 'context/form';
import { FormHelpers } from 'context/form/types';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { Flags } from 'models/Flag';
import Patient from 'models/Patient';
import { deleteEpisode, episodeQueryKeys, showEpisode } from 'services/api/episode';
import { updatePatient } from 'services/api/patient';
import { useToastActions } from 'stores/toastStore';
import colors from 'styles/theme/colors';
import CancelIcon from 'svg/CancelIcon';

import IntakeForm from './IntakeForm';

const ButtonContainer = styled.div`
  @media ${({ theme }) => theme.devices.desktop} {
    position: unset;
  }
  position: relative;
`;

const FormWrapper = styled.div`
  height: 100%;

  &:has(button) #PatientInfo {
    @media not ${({ theme }) => theme.devices.desktop} {
      margin-top: 35px;
    }
  }
`;
const RemovePatientButton = styled(Button)<{ topValue: number }>`
  position: absolute;
  @media ${({ theme }) => theme.devices.desktop} {
    top: 24px;
    right: 24px;
  }
  top: ${({ topValue = 0 }) => topValue}px;
`;

export default function EditPatientPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addToast } = useToastActions();
  const { id } = useParams<{ id?: string }>();
  const { profile } = useProfile();
  const { step, nextStep, previousStep } = useIntakeSteps();
  const canRemovePatient = profile.permissions.patientDelete ?? false;

  const isMobile = useIsMobile();
  const [showRemovePatientModal, setShowRemovePatientModal] = useState(false);
  const { mutate: removePatient } = useMutation({
    mutationFn: deleteEpisode,
  });

  const queryParams = {
    id: id ?? '',
    include: 'owner.client.enabled_provider_types,latest_location_episode.case_manager.credential',
  };

  const { data: episode } = useQuery({
    queryKey: episodeQueryKeys.show(queryParams),
    queryFn: () => showEpisode(queryParams),
    enabled: !!id,
  });

  const { mutate } = useMutation({
    mutationFn: updatePatient,
  });

  if (!episode) return <></>;

  const confirmRemovePatient = async () => {
    removePatient(
      { id: episode.id },
      {
        onSuccess: () => {
          addToast({ text: `${episode?.patient?.name} has been successfully removed.` });
          navigate('/patients');
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again' });
        },
      }
    );
  };

  const handleSubmit = async (values: Partial<FormValues>, _meta, helpers: FormHelpers<FormValues, FormMeta>) => {
    if (step !== AddEditSteps.Confirm) return;

    helpers.setMeta('isSubmitting', true);

    const patientValues = Patient.fromFormValues(values).serialize();

    mutate(
      { ...patientValues, id: episode?.patient.id },
      {
        onSuccess: (patient) => {
          addToast({ text: 'Patient successfully updated.' });
          queryClient.invalidateQueries({ queryKey: episodeQueryKeys.show(queryParams) });
          navigate(`/patients/${patient.episodeId}`);
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again.' });
        },
        onSettled: () => {
          helpers.setMeta('isSubmitting', false);
        },
      }
    );
  };

  const initialValues = episode ? Patient.fromEpisodeData(episode).intakeFormValues() : {};

  const steps = {
    [AddEditSteps.Information]: <IntakeForm patient={episode.patient} profile={profile} onNextStep={nextStep} />,
    [AddEditSteps.Confirm]: (
      <ReviewPatientDetails
        clientType={profile.clientType}
        hasCaseManagerAssignmentFlag={profile.hasFlag(Flags.CaseManagerAssignment)}
        onPreviousStep={previousStep}
      />
    ),
  };

  return (
    <>
      <FormProvider<FormValues, FormMeta>
        initialValues={initialValues}
        initialMeta={{
          isSubmitting: false,
          mode: PatientFormMode.Edit,
          patientHasActiveServiceRefusals: !!episode.locationEpisodes?.some((le) => le.hasActiveServiceRefusals),
          currentRehabState: episode.currentRehabState.state,
        }}
        onSubmit={handleSubmit}
        yupSchema={intakeFormValidation(profile.clientType)}>
        <FormWrapper>
          <FormPage>
            <Form>
              {canRemovePatient && step === AddEditSteps.Information && (
                <ButtonContainer>
                  <RemovePatientButton
                    topValue={episode.patient.externalId ? 90 : 50}
                    fullWidth={isMobile}
                    variant='outline-danger'
                    type='button'
                    onClick={() => setShowRemovePatientModal(true)}>
                    {!isMobile && <CancelIcon color={colors.accentRed} width={10} height={10} />}
                    Remove Patient
                  </RemovePatientButton>
                </ButtonContainer>
              )}
              <FormContainer>{steps[step]}</FormContainer>
            </Form>
          </FormPage>
        </FormWrapper>
      </FormProvider>
      {canRemovePatient && showRemovePatientModal && (
        <ConfirmPatientRemovalModal
          patientName={episode.patient.name}
          onCancel={() => setShowRemovePatientModal(false)}
          onConfirm={() => confirmRemovePatient()}
          colorTheme={ColorThemeName.Danger}
        />
      )}
    </>
  );
}
