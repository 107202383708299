import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  getDefaults as getActivityDefaults,
} from 'models/Activity';
import Location, { LocationOptions } from 'models/Location';

export interface ServiceRefusalOptions extends ActivityOptions {
  type: ActivityType.SERVICE_REFUSAL;
  data: Partial<
    BaseActivityDataOptions & {
      reassignedTo: Partial<LocationOptions> | null;
      refusedBy: Partial<LocationOptions> | null;
    }
  >;
}

export function getDefaults(): ServiceRefusalOptions {
  return {
    ...getActivityDefaults(),
    type: ActivityType.SERVICE_REFUSAL,
    data: {
      ...getActivityDefaults().data,
      reassignedTo: null,
      refusedBy: null,
    },
  };
}

export default class ServiceRefusal extends Activity {
  declare type: ActivityType.SERVICE_REFUSAL;
  declare data: BaseActivityData & {
    reassignedTo: Location | null;
    refusedBy: Location | null;
  };

  constructor(options: Partial<ServiceRefusalOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);

    this.data = {
      ...this.data,
      reassignedTo: opts.data.reassignedTo ? new Location(opts.data.reassignedTo) : null,
      refusedBy: opts.data.refusedBy ? new Location(opts.data.refusedBy) : null,
    };
  }

  get typeLabel() {
    return 'Service Refusal Update';
  }
}
