import React, { MutableRefObject, ReactNode, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import { Mention, MentionItem, MentionsInput, SuggestionDataItem } from 'react-mentions';
import styled from 'styled-components';

import { useQueryClient } from '@tanstack/react-query';

import { Paginated } from 'models/Paginated';
import { indexTaggableUsers, TaggableUser } from 'services/api/locationEpisode/taggableUsers';
import { colors } from 'styles/theme/colors';
import { BodySmall, BodySmallBold } from 'styles/typography';

function UserTagSuggestion(
  sug: SuggestionDataItem,
  _search: string,
  _highlightedDisplay: ReactNode,
  _index: number,
  focused: boolean
) {
  const { display } = sug;
  const userName = display!.split(' - ')[0];
  const locationName = display!.split(' - ')[1];
  return (
    <UserTagContainer $focused={focused}>
      <BodySmallBold>{userName}</BodySmallBold>
      <BodySmall>&nbsp;- {locationName}</BodySmall>
    </UserTagContainer>
  );
}

type TaggableTextAreaType = {
  disabled: boolean;
  inputRef: MutableRefObject<any>;
  locationEpisodeId?: string;
  placeholder?: string;
  taggable?: boolean;
  values: { text: string; plaintext: string; mentions: MentionItem[] };
  onChange: (value: { text: string; plaintext: string; mentions: MentionItem[] }) => void;
};

export function TaggableTextArea(props: TaggableTextAreaType) {
  const { values, onChange, disabled, inputRef, locationEpisodeId, placeholder, taggable } = props;

  const queryClient = useQueryClient();

  const formatQueryResponse = useCallback((response: AxiosResponse<Paginated<TaggableUser>>) => {
    return (
      response?.data.data.map((user: TaggableUser) => ({
        ...user,
        display: `${user.name} - ${user.groupName}`,
      })) || []
    );
  }, []);

  const fetchUsers = useCallback(
    async (search: string, callback: (data: SuggestionDataItem[]) => void) => {
      const queryKey = ['taggableUsers', { locationEpisodeId: locationEpisodeId ?? '', search }];
      const queryFn = ({ signal }) =>
        indexTaggableUsers({ locationEpisodeId: locationEpisodeId ?? '', pageSize: 10, search }, signal);

      queryClient.fetchQuery({ queryKey, queryFn }).then(formatQueryResponse).then(callback);
    },
    [formatQueryResponse, locationEpisodeId, queryClient]
  );

  // `fetchUsers` has deps that are unknown to this callback hook so it throws a benign warning
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUsers = useCallback(debounce(fetchUsers, 250), [fetchUsers]);

  const displayTransform = useCallback((_: any, display: any) => {
    const userName = display.split(' - ')[0];

    return `@${userName}`;
  }, []);

  const handleChange = (
    _event: { target: { value: string } },
    text: string,
    plaintext: string,
    mentions: MentionItem[]
  ) => {
    onChange({ text, plaintext, mentions });
  };

  const text = values.text ?? '';

  return (
    <TextAreaWrapper>
      <TextArea
        disabled={disabled}
        value={text}
        placeholder={placeholder ?? 'Start typing here to leave a note...'}
        inputRef={inputRef}
        onChange={handleChange}
        allowSpaceInQuery>
        <Mention
          appendSpaceOnAdd
          trigger='@'
          data={taggable ? debouncedFetchUsers : []}
          markup='@{{__display__||__id__}}'
          displayTransform={displayTransform}
          renderSuggestion={UserTagSuggestion}
        />
      </TextArea>
    </TextAreaWrapper>
  );
}

const TextArea = styled(MentionsInput)`
  width: 100%;
  min-height: 100%;
  textarea {
    &::placeholder {
      color: ${colors.black25};
    }
    vertical-align: top;
    font-size: 14px !important;
    line-height: 24px !important;
    padding: 0;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    background-color: transparent;
    color: transparent;
    caret-color: ${colors.black};
  }
`;

const UserTagContainer = styled.div<{ $focused: boolean }>`
  display: flex;
  padding: 6px 7px;
  background-color: ${({ $focused }) => ($focused ? colors.primaryBlue10 : colors.white)};
`;

const TextAreaWrapper = styled.div`
  height: 100%;

  ${TextArea} {
    &__highlighter {
      font-size: 14px !important;
      line-height: 24px !important;

      border: none !important;
      outline: none;
    }

    &__highlighter strong {
      color: ${colors.primaryBlue};
      background-color: ${colors.primaryBlue10};
      border-radius: ${({ theme }) => theme.dimensions.borderRadius};
      font-weight: 700 !important;
      letter-spacing: normal !important;
    }

    &__highlighter__substring {
      visibility: inherit !important;
      color: ${colors.black};
    }

    &__suggestions {
      box-shadow: 0 8px 16px 0 ${colors.boxShadow};
      border: 1px solid ${colors.black05};
      border-radius: ${({ theme }) => theme.dimensions.borderRadius};
      padding: 8px 5px !important;
      max-height: 168px;
      overflow-y: auto;
    }
  }
`;
