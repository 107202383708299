import Location from './Location';

export interface EpisodeGroupOptions {
  id: string;
  episodeId: string;
  locationId: string;
  groupId: string;
  location: Location;
}

function getDefaults(): EpisodeGroupOptions {
  return {
    id: '',
    episodeId: '',
    locationId: '',
    groupId: '',
    location: new Location(),
  };
}
/**
 * @class EpisodeGroup
 * @classdesc Represents an episode group in the system
 * @property {string} id - The episode group's id
 * @property {string} episodeId - The episode group's episode id
 * @property {string} locationId - The episode group's location id
 * @property {string} groupId - The episode group's group id
 * @property {Location} location - The episode group's location
 * @returns EpisodeGroup
 */

export default class EpisodeGroup {
  id: string;
  episodeId: string;
  locationId: string;
  groupId: string;
  location: Location;

  constructor(options: Partial<EpisodeGroupOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.episodeId = opts.episodeId;
    this.locationId = opts.locationId;
    this.groupId = opts.groupId;
    this.location = opts.location;
  }
}
