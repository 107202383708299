import React from 'react';
import styled from 'styled-components';

import { simpleDate } from 'lib/date';
import Episode from 'models/Episode';

export type ArchivedWarningBannerType = {
  episode: Episode;
};

export default function ArchivedWarningBanner(props: ArchivedWarningBannerType) {
  const { episode } = props;
  const archivedByText = episode.archivedBy?.name && ` by ${episode.archivedBy.name}`;
  const archivedByLocationText = episode.archivedByLocation?.name && ` at ${episode.archivedByLocation.name}`;

  return (
    <Banner>
      {episode.patient.name} was archived in Olio on {simpleDate(episode?.updatedAt)}
      {archivedByText}
      {archivedByLocationText}
    </Banner>
  );
}

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  text-align: center;
  background-color: #fbefcb;
  border-bottom: 1px solid #f0c02f;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;
