import { useMutation, useQueryClient } from '@tanstack/react-query';

import DischargeForm from 'components/dischargeForm/DischargeForm';
import { DischargeFormMeta, DischargeFormValues } from 'components/dischargeForm/DischargeInformation';
import { buildAnsweredQuestionsFromFormValues } from 'components/dischargeForm/helpers';
import dischargeFormValidation from 'components/dischargeForm/validation';
import StandardModal from 'components/shared/StandardModal';
import { FormProvider } from 'context/form';
import { FormHelpers, FormMeta, FormValues } from 'context/form/types';
import { simpleDashDate } from 'lib/date';
import Episode from 'models/Episode';
import { Flags } from 'models/Flag';
import LocationEpisode from 'models/LocationEpisode';
import { RehabStateOptions } from 'models/RehabState';
import { activityQueryKeys } from 'services/api/activity';
import { episodeQueryKeys } from 'services/api/episode';
import { createLocationEpisodeRehabState } from 'services/api/locationEpisodeRehabStates';
import { useToastActions } from 'stores/toastStore';

export type DischargeModalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  rehabStates: RehabStateOptions[] | null;
  episode: Episode;
  locationEpisode: LocationEpisode;
  invalidateData?: () => void;
};

export default function DischargeModal(props: DischargeModalType) {
  const { setShow, patientName, locationEpisode, rehabStates, invalidateData } = props;
  const queryClient = useQueryClient();

  const { addToast } = useToastActions();

  const { mutate } = useMutation({
    mutationFn: createLocationEpisodeRehabState,
  });

  const cancel = () => {
    setShow(false);
  };

  const handleSubmit = async (
    values: FormValues<DischargeFormValues>,
    meta: FormMeta<DischargeFormMeta>,
    helpers: FormHelpers<DischargeFormValues, DischargeFormMeta>
  ) => {
    helpers.setMeta('isSubmitting', true);

    mutate(
      {
        locationEpisodeId: locationEpisode.id,
        rehabStateId: rehabStates!.find((x) => x.state === 'Discharged')!.id,
        dischargedReason: values.dischargedReason?.label ?? '',
        dischargedLocationId: values.dischargedLocation?.id ?? undefined,
        dischargedGroupTypeId: values.dischargedReason?.groupTypeId ?? undefined,
        dischargedLocationOther: values.dischargedLocationOther || null,
        againstMedicalAdvice: values.againstMedicalAdvice || false,
        dischargeQuestions: buildAnsweredQuestionsFromFormValues(
          locationEpisode.dischargeTemplate?.questions || [],
          values.questions || {}
        ),
        enteredAt: simpleDashDate(values.actualDischargeDate ?? new Date()) as string,
        note: values.note
          ? {
              ...values.note,
              attachments: values.note.attachments?.map((upload) => upload.serialize()),
            }
          : undefined,
        needs: values.needs || undefined,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [episodeQueryKeys.base] });
          queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
          invalidateData?.();
          cancel();
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again' });
        },
        onSettled: () => {
          helpers.setMeta('isSubmitting', false);
        },
      }
    );
  };

  return (
    <StandardModal title='Discharge Patient' onCancel={cancel}>
      <FormProvider<DischargeFormValues, DischargeFormMeta>
        onSubmit={handleSubmit}
        yupSchema={dischargeFormValidation(locationEpisode.currentRehabState?.enteredAt)}>
        <DischargeForm
          locationEpisode={locationEpisode}
          patientName={patientName}
          onCancel={cancel}
          dischargeQuestionsFlagEnabled={locationEpisode.owner.client.hasFlag(Flags.DischargeQuestions)}
          showActualDischargeDate
        />
      </FormProvider>
    </StandardModal>
  );
}
