import { isDate, startOfDay } from 'date-fns';
import * as yup from 'yup';

export default function dischargeFormValidation(inTreatmentDate?: Date | null) {
  const inTreatmentDateStartOfDay = isDate(inTreatmentDate) ? startOfDay(inTreatmentDate) : undefined;

  return yup.object().shape({
    actualDischargeDate: yup.date().when([], {
      is: () => !!inTreatmentDateStartOfDay,
      then: (schema) =>
        schema
          .max(new Date(), 'Cannot select a future date/time')
          .min(inTreatmentDateStartOfDay, 'Discharge date must be on or after the date of treatment')
          .required('Actual Discharge Date is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    dischargedReason: yup
      .object()
      .shape({
        value: yup.string(),
      })
      .nullable()
      .test('dischargedReason', 'Discharge disposition is required', (obj) => Boolean(obj?.value)),
    dischargedLocation: yup
      .object()
      .shape({
        value: yup.string(),
        name: yup.string(),
      })
      .when('dischargedReason', {
        is: (dischargedReason) =>
          !!(dischargedReason.displayName || ['hospital', 'assisted living'].includes(dischargedReason?.value)),
        then: (schema) => schema.test('dischargedLocation', 'Location is required', (obj) => Boolean(obj?.name)),
        otherwise: (schema) => schema.notRequired(),
      }),
    dischargedLocationOther: yup.string().when('dischargedLocation', {
      is: (dischargedLocation) => dischargedLocation?.name === 'Other',
      then: (schema) => schema.required('Location is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    note: yup
      .object()
      .shape({
        attachments: yup.array(),
        text: yup.string(),
      })
      .when('dischargedReason', {
        is: (dischargedReason) => dischargedReason?.value === 'hospital',
        then: (schema) =>
          schema.test('note', 'Note is required', (obj) => Boolean(obj?.text || obj?.attachments?.length)),
        otherwise: (schema) => schema.notRequired(),
      }),
  });
}
