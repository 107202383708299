import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput from 'components/shared/activityInput/useActivityInput';
import AttachmentComponent from 'components/shared/Attachment';
import Button from 'components/shared/Button';
import CircularProgress from 'components/shared/CircularProgress';
import { TaggedText } from 'components/TaggedText';
import { ActivityType } from 'models/Activity';
import Escalation from 'models/Escalation';
import Profile from 'models/Profile';
import { createActivity } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { useAcknowledgeEscalation } from 'services/api/escalation';
import { useTaskModalActions } from 'stores/taskModalStore';
import { useToastActions } from 'stores/toastStore';
import { colors } from 'styles/theme/colors';
import { BodySmall, Label, LabelBold } from 'styles/typography';
import CheckIcon from 'svg/CheckIcon';

import TaskModalNotification, { NotificationTypes } from './TaskModalNotification';

type TaskModalBodyType = {
  escalation?: Escalation;
  profile: Profile;
  closeModal: () => void;
  loading: boolean;
};

export default function TaskModalBody(props: TaskModalBodyType) {
  const { closeModal, escalation, profile, loading } = props;

  const queryClient = useQueryClient();
  const { mutate, isPending: updatingEscalation } = useAcknowledgeEscalation();
  const navigate = useNavigate();
  const { addToast } = useToastActions();
  const { addViewedEpisode } = useTaskModalActions();

  const [displayNotificationType, setDisplayNotificationType] = useState<NotificationTypes | null>(null);

  const escalationType = useMemo(() => {
    return Escalation.typeForProfile(profile ?? new Profile());
  }, [profile]);

  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput({ escalationType });

  const { mutate: mutateActivity, isPending: creatingActivity } = useMutation({
    mutationFn: createActivity,
  });

  if (loading) {
    return (
      <BodyContainer>
        <StyledCircularProgress color={colors.primaryBlue} />
      </BodyContainer>
    );
  }

  if (!escalation) {
    return (
      <EmptyState>
        <Emoji>🎉</Emoji>
        <EmptyStateTitle>You're all caught up!</EmptyStateTitle>
        <BodySmall>
          No {profile.isAcute ? 'Escalations' : 'Priority Notes'} to take care of right now. Check back soon!
        </BodySmall>
      </EmptyState>
    );
  }

  const activity = escalation.activity!;
  const { summary, patient } = escalation;

  const latestRehabFacilityName = escalation.episode.rehabInformation.latestRehabFacility.name;
  const ownerName = escalation.episode.owner?.name;

  const hasAcknowledgePermission =
    profile.permissions.escalationEdit &&
    (escalation.isDefault ? profile.isAcute : profile.allowedGroupIds.includes(escalation.locationEpisode?.locationId));

  const submitdisabled = escalation.acknowledged;

  const acknowledge = async () => {
    mutate(
      {
        id: escalation.id,
      },
      {
        onSuccess: () => {
          setDisplayNotificationType(NotificationTypes.ACKNOWLEDGED);
        },
      }
    );
  };

  const handleSubmitNote = async () => {
    mutateActivity(
      {
        ...getSerializedNoteValues(),
        type: ActivityType.NOTE,
        locationEpisodeId: escalation.locationEpisode?.id,
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: attachmentsQueryKeys.count(),
          });
          resetNote();
          if (!escalation.acknowledged) {
            await acknowledge();
          } else {
            setDisplayNotificationType(NotificationTypes.NOTE_ADDED);
          }
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again.' });
        },
      }
    );
  };

  const viewPatientStory = () => {
    addViewedEpisode(escalation.episode.id);
    navigate(`/patients/${escalation.episode.id}`);
    closeModal();
  };

  return (
    <BodyContainer>
      <HeadContainer>
        <Details>
          <b>{patient.name}</b>
          <LocationDetails>
            {ownerName} — {latestRehabFacilityName}
          </LocationDetails>
        </Details>

        <PatientStoryLink onClick={viewPatientStory} role='link'>
          Go to patient story
        </PatientStoryLink>

        {hasAcknowledgePermission && (
          <AcknowledgeButton size='xsmall' onClick={acknowledge} disabled={submitdisabled} loading={updatingEscalation}>
            {!updatingEscalation && <CheckIcon width={12} height={12} />}
            Acknowledge
          </AcknowledgeButton>
        )}
      </HeadContainer>
      <BodyInnerContainer>
        <LabelBold>{summary}</LabelBold>
        <TaggedText text={activity.data.text ?? ''} $removed={false} />
        {!!activity.attachments?.length && (
          <AttachmentsContainer>
            {activity.attachments?.map((attachment) => (
              <AttachmentComponent disabled={activity.data.textRemoved} key={attachment.id} attachment={attachment} />
            ))}
          </AttachmentsContainer>
        )}
      </BodyInnerContainer>
      <FooterContainer>
        <TaskModalNotification
          displayNotificationType={displayNotificationType}
          escalationType={escalationType}
          setDisplayNotificationType={setDisplayNotificationType}
          selectedEscalation={escalation}
        />
        <ActivityInput
          values={note}
          setValues={setNote}
          onSubmit={handleSubmitNote}
          showPostButton
          heightSettings={{
            minHeight: '50px',
            maxHeight: '150px',
          }}
          placeholder={'Adding a reply to this priority will also acknowledge it...'}
          submitting={creatingActivity}
          locationEpisodeId={escalation.locationEpisode?.id}
        />
      </FooterContainer>
    </BodyContainer>
  );
}

const BodyContainer = styled.div`
  display: flex;
  flex-basis: 680px;
  flex-shrink: 1;
  flex-direction: column;
  min-width: 0;
  justify-content: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-self: center;
`;

const BodyInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
  margin-bottom: 16px;
  overflow-y: scroll;
  height: 100px;
  flex-grow: 1;
  gap: 24px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.scrollbarGray};
    border-radius: 5pt;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 24px 24px 24px;
`;

const HeadContainer = styled.div`
  border-bottom: 1px solid ${colors.black25};
  display: flex;
  padding: 16px 24px;
  min-width: 0;
  align-items: center;
`;

const LocationDetails = styled(Label)`
  color: ${colors.black75};
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 24px;
  min-width: 0;
`;

const AcknowledgeButton = styled(Button)`
  padding: 8px 10px;
  display: flex;
  gap: 8px;
  height: 34px;
  margin-left: 16px;
`;

const PatientStoryLink = styled.p`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  margin: 0;
  cursor: pointer;
`;

const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const EmptyState = styled.div`
  height: 100%;
  flex: 1;
  justify-content: center;
  padding: 0 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 14px;
`;

const Emoji = styled.p`
  font-size: 36px;
  line-height: 40px;
`;

const EmptyStateTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
`;
