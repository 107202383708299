import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  getDefaults as getActivityDefaults,
} from 'models/Activity';
import Location, { LocationOptions } from 'models/Location';
import { ReviewStatus } from 'models/Review';

export interface AltcsApplicationStatusUpdateOptions extends ActivityOptions {
  type: ActivityType.ALTCS_APPLICATION_STATUS_UPDATE;
  data: Partial<BaseActivityDataOptions & { status: ReviewStatus; provider: Partial<LocationOptions> | null }>;
}

export function getDefaults(): AltcsApplicationStatusUpdateOptions {
  const activityDefaults = getActivityDefaults();

  return {
    ...activityDefaults,
    type: ActivityType.ALTCS_APPLICATION_STATUS_UPDATE,
    data: {
      ...activityDefaults.data,
      status: ReviewStatus.PENDING,
      provider: null,
    },
  };
}

export default class AltcsApplicationStatusUpdate extends Activity {
  declare type: ActivityType.ALTCS_APPLICATION_STATUS_UPDATE;
  declare data: BaseActivityData & {
    status: ReviewStatus;
    provider: Location | null;
  };

  constructor(options: Partial<AltcsApplicationStatusUpdateOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);

    this.data = {
      ...this.data,
      provider: opts.data.provider ? new Location(opts.data.provider) : null,
    };
  }

  get typeLabel() {
    return 'ALTCS Status Update';
  }
}
